.sector-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-auto-rows: 52px;
    gap: 3px;

    @media (min-width: $screen-md-min) {
        grid-template-columns: repeat(auto-fill, minmax(98px, 1fr));
    }
}

.sector-grid__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray;
    border-radius: 5px;
    background-color: #BECED7;
    cursor: pointer;
    overflow: hidden;
    
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    transition: background-color ease 400ms;

    &::before {
        content: "";
        background-color: #000;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: opacity linear 200ms;
    }

    &:hover {
        &::before {
            opacity: 0.3;
        }
    }

    &.is-winning {
        background-color: #23B4DF;
    }

    &.is-losing {
        background-color: #DA7878;
    }

    &.is-tie {
        background-color: #B363CF;
    }

    &.is-closed {
        background-color: #BECED7;
    }

    > span {
        position: relative;
    }

    .spinner {
        display: inline-block;
        position: relative;
        width: 8px;
        height: 8px;
    
        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 14px;
            height: 14px;
            top: -2px;
            left: -2px;
            border: 2px solid #fff;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #fff transparent transparent transparent;
    
            &:nth-child(1) {
                animation-delay: -0.45s;
            }
    
            &:nth-child(2) {
                animation-delay: -0.3s;
            }
    
            &:nth-child(3) {
                animation-delay: -0.15s;
            }
        }
    }
}

.legend-wrapper {
    display: flex;
    justify-content: space-between;

    @media (min-width: $screen-md-min) {
        max-width: 500px;
    }

    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
}

.legend-wrapper .legend {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
        max-width: 50%;
        flex-basis: 50%;
        margin-bottom: 12px;
    }
}

.legend-wrapper .legend__color {
    width: 24px;
    height: 18px;
    border-radius: 5px;
    background-color: #BECED7;
    margin-right: 8px;
    color: #656565;
}

.legend-wrapper .legend__label {
    font-size: 12px;
}

.legend-wrapper .legend--winning {
    .legend__color {
        background-color: #23B4DF;
    }
}

.legend-wrapper .legend--losing {
    .legend__color {
        background-color: #DA7878;
    }
}

.legend-wrapper .legend--tie {
    .legend__color {
        background-color: #B363CF;
    }
}

.result-header-stats {
    padding: 40px 60px;
    text-align: center;

    &.is-hidden {
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.result-header-chart {
    position: relative;
    padding: 32px;
    border-radius: 24px;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 8px 16px rgba(15, 34, 67, 0.06), 0px 0px 1px rgba(15, 34, 67, 0.08);
    height: 275px;
    opacity: 0;
    transition: opacity linear 250ms;

    &.is-ready {
        opacity: 1;
    }

    &.is-hidden {
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.section-stats__numbers {
    font-size: 20px;
    color: #656565;
}

.section-stats__number {
    font-weight: bold;
    color: #131E26;
}

.section-stats__label {
    font-weight: bold;
    color: #656565;
    font-size: 14px;
}

.result-winner__label {
    font-weight: bold;
    color: #131E26;
}

.sector-modal__party {
    display: flex;
    align-items: center;

    .form-group {
        width: 100%;
    }
}

.sector-modal__logo {
    display: flex;
    justify-content: center;
    width: 70px;
    margin-right: 16px;

    img {
        max-width: 100%;
        height: 60px;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// HOTFIX(28738108): Retirer le overflow: hidden; Ne fonctionne pas sur mobile
// TODO: Refactor pour utiliser le modal à l'extérieur du controller
body.election-manual {
    &.modal-open {
        @media only screen and (max-width: $mq-up-to-tablet) {
            overflow: auto;
        }
    }
}