#map-leaflet {
    height: 85vh;
    width: 100%;
}

.leaflet-control-locationlist {
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    padding: 6px 10px 6px 6px;
    color: #333;
    background: #fff;
    border-radius: 5px;
}

.leaflet-touch .leaflet-bar.leaflet-control-locationlist-form {
    border: 0;
}

.leaflet-control-locationlist-list {
    border: 0;
    font-size: 15px;
    font-weight: bold;
}

.info {
    padding: 6px 8px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-width: 100px;
    background-color: white;
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.sv-marker {
    display: block;
    font-weight: bold;
    letter-spacing: 2px;
    white-space: nowrap;
    pointer-events: none !important;
}

.leaflet-control-infoBox {
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    padding: 6px 10px 6px 6px;
    color: #333;
    background-color: white;
    border-radius: 5px;

    .is-winner {
        background-color: gold;
    }

    h4 {
        margin-top: 6px;
        color: #777;
        font-size: rem(15px);
    }

    p {
        margin: 0;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
    }

    th, td {
        text-align: center;
        padding: 6px;
    }

    th:first-child,
    td:first-child {
        text-align: left;
    }

    th:first-child {
        font-size: 16px;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2
    }

    button {
        margin-top: 8px;
    }
}


.leaflet-control-layers-group {
    label:not(.leaflet-control-layers-group-label) {
        margin-left: 8px;
    }

    .leaflet-control-layers-group-label {
        font-size: 14px;
        margin-bottom: 5px;
    }
}


.fullscreen-icon {
    background-image: url("/img/leaflet-fullscreen.svg");
}

.leaflet-control-infoBox,
.leaflet-control-locationlist,
.leaflet-control-layers.leaflet-control-layers-expanded {
    width: 240px;
}