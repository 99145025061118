@charset "UTF-8";
/* @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap"; */
/*!
 *
 * Angle - Bootstrap Admin App + jQuery
 *
 * Version: 3.3.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */
/* [Table of contents]

1. Bootstrap reset
  1.1 bootstrap
  1.2 bootstrap-reset

2. Typography
  2.1 typo

3. Layout
  3.1 layout / .wrapper .content-wrapper
  3.2 top-navbar / .top-navbar
  3.3 sidebar / .sidebar
  3.4 offsidebar / .offsidebar

4. Components
  4.1  button-extra
  4.2  placeholder / .box-placeholder
  4.3  panels
  4.4  progress-extra
  4.5  radial-bar / .radial-bar
  4.6  user-block / .user-block
  4.7  circles / .circle
  4.8  timeline / .timeline
  4.9  dropdown-extra
  4.10 row-extra / .row-table .row-flush
  4.11 half-float / .half-float
  4.12 masonry-grid / .row-masonry
  4.13 widget / .widget
  4.14 typeahead / .typeahead-ctrl
  4.15 animate
  4.16 table-grid
  4.17 todo / .todo
  4.18 nestable

5. Charts
  5.1 chart-flot / .flot-chart
  5.2 chart-easypie / .easypie-chart

6. Form elements
  6.1 form-elements
  6.2 form-validation
  6.3 form-tags
  6.4 form-wizard
  6.5 form-imgcrop

7. Tables
  7.1 datatable
  7.2 table-extras
  7.3 jqgrid

8. Plugins
  8.1 plugins
  8.2 slim-scroll / .slimScrollBar
  8.3 alerts / .alerts
  8.4 notifiy / .notifiy
  8.5 calendar / .fc-*
  8.6 spinner / .whirl
  8.7 gmap / .gmap
  8.8 vector-map / vector-map
  8.9 portlets / .portlet

9. Utilities
  9.1 utils

10. Print CSS
  10.1 print

11. Settings
  11.1 settings

12. Documentation
  12.1 docs
 */
/* ========================================================================
     Component: media-queries
 ========================================================================== */
/* ========================================================================
     Component: bootstrap-reset
 ========================================================================== */
@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic);
*:focus {
  outline: 0 !important; }

a {
  outline: none !important; }
  a.text-muted:hover, a.text-muted:focus {
    color: #748690; }

hr {
  border-top: 1px solid #e4eaec; }

[data-now] {
  display: inline-block; }

.list-group {
  line-height: 1.3; }
  .list-group .list-group-item {
    padding: 10px; }
    .panel > .list-group .list-group-item {
      border-top: 0; }

.navbar-toggle .icon-bar {
  background-color: #fff; }

.page-header {
  border-bottom-color: #e4eaec; }

.panel .panel-heading {
  border-bottom: 0;
  font-size: 14px; }
  .panel .panel-heading a {
    text-decoration: none !important; }

.panel.panel-default {
  border-top-width: 3px; }

.well {
  border: 1px solid #dde6e9; }

.jumbotron {
  border: 1px solid #dde6e9; }
  @media only screen and (min-width: 768px) {
    .jumbotron {
      padding: 30px 15px; } }

.nav-tabs > li > a {
  color: #515253;
  background-color: #edf1f2;
  margin: 0;
  border: 1px solid #dde6e9;
  border-radius: 0;
  padding: 15px 20px; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: inherit;
  border-bottom-color: #fff; }

.tab-content {
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #dde6e9; }
  .nav-pills + .tab-content {
    border: 0;
    padding: 0; }

.btn, .ColVis .ColVis_Button {
  border-radius: 3px;
  font-size: 13px;
  border-color: transparent;
  -webkit-appearance: none;
  outline: none !important;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s; }
  .btn.btn-link, .ColVis .btn-link.ColVis_Button {
    box-shadow: none;
    border: 0; }
  .btn.btn-default, .ColVis .ColVis_Button {
    border-color: #eaeaea; }
  .input-group .btn, .input-group .ColVis .ColVis_Button, .ColVis .input-group .ColVis_Button {
    font-size: 14px;
    border-color: #dde6e9; }
  .input-group .input-sm + .input-group-btn .btn, .input-group .input-sm + .input-group-btn .ColVis .ColVis_Button, .ColVis .input-group .input-sm + .input-group-btn .ColVis_Button {
    font-size: 13px; }

.form-control, body .bootstrap-tagsinput {
  box-shadow: 0 0 0 #000 !important; }

.input-sm,
select.input-sm {
  height: 31px; }

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px; }
  fieldset.last-child, fieldset:last-child {
    border-bottom: 0; }
  fieldset .form-group {
    margin-bottom: 0; }

@media only screen and (max-width: 767px) {
  input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
    -webkit-appearance: none; } }

.table > thead > tr > th {
  border-bottom-width: 1px; }

.table > tbody + tbody {
  border-bottom-width: 1px; }

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px; }

.progress {
  -webkit-box-shadow: 0 0 0 #000;
  box-shadow: 0 0 0 #000;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
  background-color: #fff; }
  .progress .progress-bar {
    -webkit-box-shadow: 0 0 0 #000;
    box-shadow: 0 0 0 #000; }

.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid #e4eaec;
  border-radius: 3px; }
  .popover .popover-title {
    border: 0; }

.nav.nav-pills .active > a {
  background-color: #5d9cec; }

.dropdown-menu {
  border-radius: 3px; }

.dropdown-header {
  color: #a1a2a3; }

.navbar-top .navbar-nav > .active > a {
  color: #999; }
  .navbar-top .navbar-nav > .active > a:hover, .navbar-top .navbar-nav > .active > a:focus {
    color: #d1d2d3; }

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #666; }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #222; }

.carousel .carousel-indicators {
  bottom: 0; }

.carousel .carousel-control.left, .carousel .carousel-control.right {
  background-image: none; }

.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px; }

.modal-backdrop {
  position: fixed;
  bottom: 0; }

.topnavbar,
.navbar,
.navbar .dropdown-menu {
  filter: none !important; }

.glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .glyphicon.glyphicon-chevron-right:before {
    content: ""; }
  .glyphicon.glyphicon-chevron-left:before {
    content: ""; }
  .glyphicon.glyphicon-chevron-up:before {
    content: ""; }
  .glyphicon.glyphicon-chevron-down:before {
    content: ""; }
  .glyphicon.glyphicon-folder-open:before {
    content: ""; }

/* ========================================================================
     Component: typo
 ========================================================================== */
body {
  font-family: "Source Sans Pro", sans-serif;
  color: #656565; }

h1, h2, h3, h4 {
  font-weight: bold; }

.strong {
  font-weight: bold; }

/* ========================================================================
     Component: layout
 ========================================================================== */
html {
  /* $replace rtl */
  direction: ltr;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

html, body {
  height: 100%; }

.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden; }
  .wrapper.ng-leave {
    display: none; }
  .wrapper > .aside {
    position: absolute;
    width: 220px;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 116;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #fff; }
    .wrapper > .aside .aside-inner {
      padding-top: 110px;
      height: 100%;
      width: 220px;
      overflow: hidden; }
    .wrapper > .aside > .nav-floating {
      left: inherit;
      margin-left: 220px;
      z-index: 1110;
      min-width: 190px;
      overflow: auto; }
  .wrapper .dropdown-backdrop {
    z-index: 109; }
  .wrapper > section {
    position: relative;
    height: 100%;
    margin-left: 0;
    z-index: 111;
    background-color: #f5f7fa;
    margin-bottom: 60px !important; }
  .wrapper > footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    border-top: 1px solid #e4eaec;
    padding: 20px;
    z-index: 109; }
    .wrapper > footer > p {
      margin: 0; }

.modal-open .wrapper {
  z-index: 0; }

.content-wrapper {
  padding: 15px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px; }
  .content-wrapper > .unwrap {
    margin: -15px; }
    @media only screen and (min-width: 768px) {
      .content-wrapper > .unwrap {
        margin: -20px; } }
  .content-wrapper > h3,
  .content-wrapper > .content-heading {
    font-size: 24px;
    line-height: 1.1;
    color: #929292;
    margin: -15px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;
    background-color: #fafbfc;
    border-bottom: 1px solid #cfdbe2; }
    .content-wrapper > h3 > small,
    .content-wrapper > .content-heading > small {
      display: block;
      font-size: 12px;
      color: #909FA7; }
  .content-wrapper > button, .content-wrapper > .btn, .ColVis .content-wrapper > .ColVis_Button {
    margin: 10px 10px 0 0; }
  .content-wrapper .container,
  .content-wrapper .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media only screen and (min-width: 768px) {
    .content-wrapper {
      padding: 20px; }
      .content-wrapper > h3,
      .content-wrapper > .content-heading {
        margin: -20px;
        margin-bottom: 20px;
        padding: 20px; }
      .content-wrapper > button, .content-wrapper > .btn, .ColVis .content-wrapper > .ColVis_Button {
        margin: 0; } }

@media only screen and (min-width: 768px) {
  body {
    min-height: 100%; }
  .wrapper > .aside .aside-inner {
    padding-top: 55px; }
  .wrapper > section,
  .wrapper > footer {
    margin-left: 220px; }
  .wrapper > section.has-sidebar-right {
    margin-right: 240px; }
    .wrapper > section.has-sidebar-right + .offsidebar {
      z-index: 1; } }

@media only screen and (max-width: 767px) {
  .wrapper > .aside {
    margin-left: -220px; }
  .aside-toggled .wrapper > section,
  .aside-toggled .wrapper > footer {
    margin-left: 220px; }
  .aside-toggled .wrapper > .aside {
    margin-left: 0; }
  .csstransforms3d .wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden; }
    .csstransforms3d .wrapper > section,
    .csstransforms3d .wrapper > footer {
      margin-left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transition: -webkit-transform 0.3s ease;
      -moz-transition: -moz-transform 0.3s ease;
      -o-transition: -o-transform 0.3s ease;
      transition: transform 0.3s ease; }
    .csstransforms3d .wrapper > .aside {
      margin-left: 0;
      -webkit-transform: translate3d(-220px, 0, 0);
      transform: translate3d(-220px, 0, 0);
      -webkit-transition: -webkit-transform 0.3s ease;
      -moz-transition: -moz-transform 0.3s ease;
      -o-transition: -o-transform 0.3s ease;
      transition: transform 0.3s ease; }
  .csstransforms3d .aside-toggled .wrapper > section,
  .csstransforms3d .aside-toggled .wrapper > footer {
    -webkit-transform: translate3d(220px, 0, 0);
    transform: translate3d(220px, 0, 0); }
  .csstransforms3d .aside-toggled .wrapper > .aside {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@media only screen and (max-width: 767px) {
  .aside-collapsed .wrapper > .aside {
    margin-left: -70px; }
  .aside-collapsed.aside-toggled .wrapper > section,
  .aside-collapsed.aside-toggled .wrapper > footer {
    margin-left: 70px; }
  .aside-collapsed.aside-toggled .wrapper > .aside {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed .wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed .wrapper > section,
    .csstransforms3d .aside-collapsed .wrapper > footer {
      margin-left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transition: -webkit-transform 0.3s ease;
      -moz-transition: -moz-transform 0.3s ease;
      -o-transition: -o-transform 0.3s ease;
      transition: transform 0.3s ease; }
    .csstransforms3d .aside-collapsed .wrapper > .aside {
      margin-left: 0;
      -webkit-transform: translate3d(-70px, 0, 0);
      transform: translate3d(-70px, 0, 0);
      -webkit-transition: -webkit-transform 0.3s ease;
      -moz-transition: -moz-transform 0.3s ease;
      -o-transition: -o-transform 0.3s ease;
      transition: transform 0.3s ease; }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper > section,
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper > footer {
    -webkit-transform: translate3d(70px, 0, 0);
    transform: translate3d(70px, 0, 0); }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper > .aside {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.aside-collapsed {
  overflow-y: auto; }
  .aside-collapsed .wrapper > .aside, .aside-collapsed .wrapper > .aside > .aside-inner {
    width: 70px; }
  .aside-collapsed .wrapper > .aside > .nav-floating {
    margin-left: 70px; }

@media only screen and (min-width: 768px) {
  .aside-collapsed .wrapper > section,
  .aside-collapsed .wrapper > footer {
    margin-left: 70px; } }

@media (max-width: 1140px) {
  .layout-fixed.aside-toggled, .layout-fixed.offsidebar-open {
    overflow-y: hidden; } }

.layout-fixed .wrapper {
  /* only applied to sidebar */ }
  .layout-fixed .wrapper .topnavbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 121; }
  .layout-fixed .wrapper > aside {
    position: fixed; }
  .layout-fixed .wrapper > .aside {
    /* safari fix */
    height: 1px;
    min-height: 100%;
    /* ios blanks space fix */ }
    .layout-fixed .wrapper > .aside > .aside-inner {
      position: fixed;
      top: 0;
      bottom: 0; }
  .layout-fixed .wrapper > section {
    margin-top: 114px; }
    @media only screen and (min-width: 768px) {
      .layout-fixed .wrapper > section {
        margin-top: 55px; } }

/* IE10+ hack: safari fix breaks ie so we need to target ie only to restore */
_:-ms-lang(x), .layout-fixed .wrapper > .aside > .aside-inner {
  position: static !important; }

@media only screen and (min-width: 992px) {
  .layout-boxed {
    overflow: auto !important; }
    .layout-boxed .wrapper {
      margin: 0 auto;
      overflow: hidden;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.25); }
      .layout-boxed .wrapper .offsidebar {
        position: absolute !important; }
      .layout-boxed .wrapper > .aside {
        left: inherit; }
      .layout-boxed .wrapper, .layout-boxed .wrapper .topnavbar-wrapper {
        width: 970px; }
    .layout-boxed.layout-fixed .wrapper > .aside > .aside-inner {
      left: inherit; } }

@media only screen and (min-width: 1200px) {
  .layout-boxed .wrapper, .layout-boxed .wrapper .topnavbar-wrapper {
    width: 1140px; } }

/* ========================================================================
   Component: layout-extra
 ========================================================================== */
.hidden-footer .wrapper > footer {
  display: none; }

.hidden-footer .wrapper > section {
  margin-bottom: 0 !important; }

.layout-fs .wrapper > section {
  position: absolute;
  top: 114px;
  left: 0;
  right: 0;
  bottom: 60px;
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  @media only screen and (min-width: 480px) {
    .layout-fs .wrapper > section {
      overflow: hidden; } }
  @media only screen and (min-width: 768px) {
    .layout-fs .wrapper > section {
      top: 55px; } }
  .layout-fs .wrapper > section > .content-wrapper {
    width: 100%;
    height: 100%;
    padding: 0; }

.layout-fs.hidden-footer .wrapper > section {
  bottom: 0; }

.d-table,
.d-table-fixed {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 240px;
  border-spacing: 0; }

.d-table-fixed {
  table-layout: fixed; }

.d-row {
  display: table-row;
  height: 100%; }

.d-cell,
.d-cell-wrapper {
  position: relative;
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: top;
  overflow: auto; }

.d-cell-wrapper {
  display: block; }
  .d-cell-wrapper .d-cell-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

@media only screen and (max-width: 767px) {
  .d-table-fixed {
    display: block; }
    .d-table-fixed > .d-cell {
      display: block;
      width: auto;
      height: auto; } }

/* Target Firefox only */
@-moz-document url-prefix() {
  .d-table, .d-table-desktop {
    height: 240px;
    min-height: 100%; }
  .d-cell-inner {
    overflow: auto; } }

.fh {
  height: 100% !important; }

.fw {
  width: 100% !important; }

.scroll {
  overflow: auto; }

.scroll-y {
  overflow-y: auto; }

.scroll-y {
  overflow-y: auto; }

.layout-h .wrapper > section {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .layout-h .wrapper > section > .content-wrapper > h3 {
    display: none; }

.layout-h .wrapper > section {
  margin-top: 0; }

.layout-h .wrapper > .offsidebar {
  margin-top: 55px; }

.layout-h .wrapper > footer {
  margin: 0; }

.layout-h.layout-fixed .wrapper > section,
.layout-h.layout-fixed .wrapper > .offsidebar {
  margin-top: 55px; }

.aside-float .wrapper {
  box-shadow: 0 0 0 #000; }
  .aside-float .wrapper > footer {
    border: 0; }
  .aside-float .wrapper > .aside {
    padding: 15px 0;
    background: transparent; }
    .aside-float .wrapper > .aside > .aside-inner {
      height: 100%;
      top: 15px;
      bottom: 15px;
      left: inherit; }
    .aside-float .wrapper > .aside .sidebar {
      border: 1px solid #e4eaec; }
    .aside-float .wrapper > .aside .sidebar:after {
      display: none; }
  @media only screen and (min-width: 768px) {
    .aside-float .wrapper > section {
      padding-left: 15px; }
    .aside-float .wrapper > footer {
      left: 15px; } }
  .aside-float .wrapper .content-wrapper {
    border: 0; }
    .aside-float .wrapper .content-wrapper > h3,
    .aside-float .wrapper .content-wrapper > .content-heading {
      padding-top: 30px;
      border: 0;
      background-color: transparent; }
    .aside-float .wrapper .content-wrapper > .unwrap {
      margin: 0 !important; }

.aside-float.aside-toggled .wrapper > .aside {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }
  .aside-float.aside-toggled .wrapper > .aside > .aside-inner {
    margin-left: 15px; }

.aside-float.aside-toggled .wrapper > section {
  padding-left: 15px; }

.aside-float.aside-toggled .wrapper > footer {
  left: 15px; }

@media only screen and (min-width: 768px) {
  .aside-float.aside-collapsed .topnavbar .navbar-header {
    width: 100px; }
  .aside-float.layout-fs .wrapper > section > .content-wrapper {
    padding: 0 0 0 20px; }
  .aside-float .wrapper > .aside > .aside-inner {
    margin-left: 15px; } }

@media only screen and (min-width: 992px) {
  .aside-float.layout-boxed .wrapper > .aside > .aside-inner {
    margin-left: 0; }
  .aside-float.layout-boxed .wrapper > section {
    padding-left: 0; } }

.aside-float.aside-toggled.layout-fs .wrapper > section > .content-wrapper {
  padding: 0 0 0 20px; }

/* ========================================================================
   Component: layout-animation.less
 ========================================================================== */
.wrapper > .aside {
  -webkit-transition: width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1);
  -o-transition: width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1);
  transition: width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1); }

.aside-inner,
.navbar-header,
.sidebar > .nav > li {
  -webkit-transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  -o-transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.wrapper > section {
  -webkit-transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  -o-transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.sidebar > .nav .label {
  -webkit-animation: fadeInRight 1s;
  -o-animation: fadeInRight 1s;
  animation: fadeInRight 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.aside-collapsed .sidebar > .nav .label {
  -webkit-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  animation: fadeIn 1s; }

.sidebar .nav > li > a {
  -webkit-animation: fadeInLeft 0.5s;
  -o-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s; }

.sidebar > .nav > .nav-heading,
.sidebar > .nav > li > a > span,
.navbar-brand .brand-logo {
  -webkit-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  animation: fadeIn 1s; }

.sidebar li > a, .sidebar li > .nav-item,
.sidebar > .nav > .nav-heading {
  white-space: nowrap; }

.aside-collapsed .user-block-picture {
  -webkit-transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  -o-transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.aside-collapsed .user-block {
  -webkit-transition: padding 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  -o-transition: padding 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  transition: padding 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

/* ========================================================================
     Component: top-navbar
 ========================================================================== */
.topnavbar {
  -webkit-backface-visibility: hidden;
  /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  background-color: #fff;
  z-index: 1050;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  @media only screen and (min-width: 768px) {
    .topnavbar .navbar-header {
      width: 220px;
      text-align: center; }
      .topnavbar .navbar-header .navbar-brand {
        width: 100%; } }

.topnavbar {
  position: relative; }
  .topnavbar .navbar-header {
    background-color: transparent;
    background-image: -webkit-linear-gradient(left, #23b7e5 0%, #51c6ea 100%);
    background-image: -o-linear-gradient(left, #23b7e5 0%, #51c6ea 100%);
    background-image: linear-gradient(to right, #23b7e5 0%, #51c6ea 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF23B7E5', endColorstr='#FF51C6EA', GradientType=1); }
    @media only screen and (min-width: 768px) {
      .topnavbar .navbar-header {
        background-image: none; } }
  .topnavbar .navbar-header {
    position: relative;
    z-index: 11; }
    .topnavbar .navbar-header .navbar-brand {
      padding: 0; }
    .topnavbar .navbar-header .brand-logo > img,
    .topnavbar .navbar-header .brand-logo-collapsed > img {
      margin: 0 auto; }
    .topnavbar .navbar-header .brand-logo {
      display: block;
      padding: 10px 15px; }
    .topnavbar .navbar-header .brand-logo-collapsed {
      display: none;
      padding: 6px 15px; }
  .topnavbar .navbar-nav > li > a,
  .topnavbar .navbar-nav > .open > a {
    color: #23b7e5; }
    .topnavbar .navbar-nav > li > a:hover, .topnavbar .navbar-nav > li > a:focus,
    .topnavbar .navbar-nav > .open > a:hover,
    .topnavbar .navbar-nav > .open > a:focus {
      color: #117391; }
  .topnavbar .navbar-nav > .active > a, .topnavbar .navbar-nav > .active > a:hover, .topnavbar .navbar-nav > .active > a:focus,
  .topnavbar .navbar-nav > .open > a,
  .topnavbar .navbar-nav > .open > a:hover,
  .topnavbar .navbar-nav > .open > a:focus {
    background-color: transparent; }
  .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    line-height: 55px;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    @media only screen and (min-width: 768px) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        color: #fff; } }

@media only screen and (max-width: 767px) {
  .sidebar-toggle {
    position: absolute !important;
    top: 5px;
    right: 0;
    z-index: 3001; }
    .sidebar-toggle > em {
      color: white; } }

.nav-wrapper {
  padding: 0 15px;
  background-color: transparent; }
  .nav-wrapper .nav.navbar-nav {
    float: left; }
    .nav-wrapper .nav.navbar-nav.navbar-right {
      float: right; }
  .nav-wrapper .nav > li {
    position: static;
    float: left; }
  .nav-wrapper .navbar-nav .open .dropdown-menu {
    position: absolute;
    background-color: white;
    left: 0px;
    right: 0px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1; }

.topnavbar .navbar-form {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: 55px;
  z-index: 9001;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 0;
  border-bottom: 1px solid #e1e2e3; }
  .topnavbar .navbar-form .form-group {
    height: 100%;
    width: 100%; }
  .topnavbar .navbar-form .form-control, .topnavbar .navbar-form body .bootstrap-tagsinput, body .topnavbar .navbar-form .bootstrap-tagsinput {
    height: 100%;
    border: 0;
    border-radius: 0;
    width: 100%; }
  .topnavbar .navbar-form.open {
    top: 0; }
  .topnavbar .navbar-form .has-feedback .form-control-feedback {
    height: 30px;
    cursor: pointer;
    top: 50%;
    margin-top: -15px;
    line-height: 30px;
    margin-right: 10px;
    color: #c1c2c3;
    font-size: 1.5em;
    pointer-events: auto; }

@media only screen and (min-width: 768px) {
  .topnavbar .navbar-form {
    left: 220px; } }

@media only screen and (min-width: 768px) {
  .topnavbar {
    border: 0;
    background-color: #23b7e5;
    background-image: -webkit-linear-gradient(left, #23b7e5 0%, #51c6ea 100%);
    background-image: -o-linear-gradient(left, #23b7e5 0%, #51c6ea 100%);
    background-image: linear-gradient(to right, #23b7e5 0%, #51c6ea 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF23B7E5', endColorstr='#FF51C6EA', GradientType=1); }
    .topnavbar .navbar-header {
      background-image: none;
      background-repeat: no-repeat;
      filter: none; }
    .topnavbar .navbar-nav > a {
      box-shadow: 0 0 0 #000 inset;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s; }
    .topnavbar .navbar-nav > .open > a, .topnavbar .navbar-nav > .open > a:hover, .topnavbar .navbar-nav > .open > a:focus {
      box-shadow: 0 -3px 0 #5d9cec inset;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s; }
    .topnavbar .navbar-nav > li > a,
    .topnavbar .navbar-nav > .open > a {
      color: #fff; }
      .topnavbar .navbar-nav > li > a:hover, .topnavbar .navbar-nav > li > a:focus,
      .topnavbar .navbar-nav > .open > a:hover,
      .topnavbar .navbar-nav > .open > a:focus {
        color: #117391; }
    .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
      position: static; }
  .nav-wrapper {
    position: relative;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    z-index: 10; }
    .nav-wrapper:before, .nav-wrapper:after {
      content: " ";
      display: table; }
    .nav-wrapper:after {
      clear: both; }
    .nav-wrapper .nav > li {
      position: relative; }
    .nav-wrapper .navbar-nav .open .dropdown-menu {
      left: auto;
      right: auto; }
    .nav-wrapper .navbar-nav.navbar-right .open .dropdown-menu {
      left: auto;
      right: 0; } }

@media only screen and (min-width: 768px) {
  .aside-collapsed .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed .topnavbar .navbar-header {
    width: 70px; }
  .aside-collapsed .topnavbar .navbar-form {
    left: 70px; } }

/* ========================================================================
     Component: sidebar
 ========================================================================== */
.sidebar {
  height: 100%;
  padding-bottom: 20px;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }
  .sidebar:after {
    content: "";
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    -webkit-transform: translateZ(0px); }
  .sidebar .nav-heading {
    padding: 12px 15px;
    color: #919DA8;
    font-size: 13px;
    letter-spacing: .035em;
    pointer-events: none;
    cursor: default; }
  .sidebar > .nav {
    position: relative; }
    .sidebar > .nav > .nav-heading:first-child {
      padding-top: 20px; }
    .sidebar > .nav > li {
      border-left: 3px solid transparent;
      -webkit-transition: border-left-color 0.4s ease;
      -o-transition: border-left-color 0.4s ease;
      transition: border-left-color 0.4s ease; }
      .sidebar > .nav > li > a,
      .sidebar > .nav > li > .nav-item {
        padding: 12px 24px;
        color: #515253;
        letter-spacing: .025em;
        font-weight: normal;
        cursor: pointer; }
        .sidebar > .nav > li > a:focus, .sidebar > .nav > li > a:hover,
        .sidebar > .nav > li > .nav-item:focus,
        .sidebar > .nav > li > .nav-item:hover {
          text-decoration: none;
          outline: none;
          color: #23b7e5; }
        .sidebar > .nav > li > a > em,
        .sidebar > .nav > li > .nav-item > em {
          width: 1.8em;
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          color: inherits; }
      .sidebar > .nav > li.active,
      .sidebar > .nav > li.active > a,
      .sidebar > .nav > li.active > .nav-item,
      .sidebar > .nav > li.active .nav, .sidebar > .nav > li.open,
      .sidebar > .nav > li.open > a,
      .sidebar > .nav > li.open > .nav-item,
      .sidebar > .nav > li.open .nav {
        background-color: #fcfcfc;
        color: #23b7e5; }
      .sidebar > .nav > li.active > .nav-item > em,
      .sidebar > .nav > li.active > a > em, .sidebar > .nav > li.open > .nav-item > em,
      .sidebar > .nav > li.open > a > em {
        color: #23b7e5; }
      .sidebar > .nav > li.active {
        border-left-color: #23b7e5; }
    .sidebar > .nav .label {
      display: block; }

@media only screen and (min-width: 1025px) {
  .sidebar:not(.show-scrollbar) {
    margin-right: -17px;
    overflow-y: scroll; } }

.sidebar-subnav {
  background-color: #fff; }
  .sidebar-subnav > .sidebar-subnav-header {
    color: #515253;
    display: none;
    padding: 10px 20px;
    font-weight: bold; }
  .sidebar-subnav > li {
    border-left: 0 !important; }
    .sidebar-subnav > li > a,
    .sidebar-subnav > li > .nav-item {
      display: block;
      position: relative;
      padding: 10px 20px;
      padding-left: 53px;
      font-weight: normal;
      background-color: transparent !important;
      color: #515253; }
      .sidebar-subnav > li > a:focus, .sidebar-subnav > li > a:hover,
      .sidebar-subnav > li > .nav-item:focus,
      .sidebar-subnav > li > .nav-item:hover {
        color: #23b7e5; }
      .sidebar-subnav > li > a > em,
      .sidebar-subnav > li > .nav-item > em {
        display: inline-block;
        width: 1.8em;
        margin: 0 0 0 -2em; }
    .sidebar-subnav > li.active > a,
    .sidebar-subnav > li.active > .nav-item {
      color: #23b7e5; }
      .sidebar-subnav > li.active > a:after,
      .sidebar-subnav > li.active > .nav-item:after {
        border-color: #23b7e5;
        background-color: #23b7e5; }
    .sidebar-subnav > li .nav > li {
      padding-left: 5px; }
  .sidebar-subnav.nav-floating {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: -1px; }
    .sidebar-subnav.nav-floating, .sidebar-subnav.nav-floating .collapse {
      height: auto !important;
      display: block !important;
      visibility: visible !important; }
    .sidebar-subnav.nav-floating > .sidebar-subnav-header {
      display: block; }
    .sidebar-subnav.nav-floating li > a,
    .sidebar-subnav.nav-floating li > .nav-item {
      padding-left: 20px; }
      .sidebar-subnav.nav-floating li > a em,
      .sidebar-subnav.nav-floating li > .nav-item em {
        margin-left: 0; }

@media only screen and (min-width: 768px) {
  .sidebar > .nav .label {
    margin: 2px 0 0 0; } }

.aside-collapsed .sidebar {
  overflow-x: hidden; }
  .aside-collapsed .sidebar > .nav .nav,
  .aside-collapsed .sidebar > .nav > .nav-heading,
  .aside-collapsed .sidebar > .nav > li > .nav-item > span,
  .aside-collapsed .sidebar > .nav > li > a > span {
    display: none !important; }
  .aside-collapsed .sidebar > .nav > li {
    width: 69px; }
    .aside-collapsed .sidebar > .nav > li > a, .aside-collapsed .sidebar > .nav > li > .nav-item {
      text-indent: -3px;
      padding: 20px 0;
      text-align: center; }
      .aside-collapsed .sidebar > .nav > li > a > em, .aside-collapsed .sidebar > .nav > li > .nav-item > em {
        font-size: 1.6em;
        width: auto; }
      .aside-collapsed .sidebar > .nav > li > a:focus, .aside-collapsed .sidebar > .nav > li > .nav-item:focus {
        background-color: transparent; }
        .aside-collapsed .sidebar > .nav > li > a:focus > em, .aside-collapsed .sidebar > .nav > li > .nav-item:focus > em {
          color: inherit; }
  .aside-collapsed .sidebar .nav .label {
    position: absolute;
    top: 10px;
    right: 5px;
    text-indent: 0; }

/* ========================================================================
     Component: offsidebar
 ========================================================================== */
.offsidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 235px;
  margin-top: 114px;
  border-left: 1px solid #cccccc;
  background-color: #fff;
  color: #515253;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1; }
  .offsidebar > nav {
    min-height: 100%;
    overflow: hidden;
    -webkit-transform: translateZ(0px); }
  .offsidebar .progress {
    border: 0; }
  .offsidebar .nav > li > a:hover, .offsidebar .nav > li > a:focus {
    background-color: rgba(0, 0, 0, 0.025); }
  .offsidebar .tab-content {
    padding: 0;
    border: 0; }
  .offsidebar .nav-tabs.nav-justified > li > a, .offsidebar .nav-tabs.nav-justified > li > a:hover, .offsidebar .nav-tabs.nav-justified > li > a:focus {
    background-color: transparent;
    border: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0;
    color: #909FA7; }
  .offsidebar .nav-tabs.nav-justified > li.active > a {
    color: #5d9cec; }
  @media only screen and (min-width: 768px) {
    .offsidebar {
      margin-top: 55px; } }

.offsidebar-open .offsidebar {
  overflow-y: auto;
  z-index: 116; }

@media only screen and (min-width: 768px) {
  .offsidebar-open {
    overflow-y: auto; } }

.offsidebar {
  right: -235px; }

.no-csstransforms3d .offsidebar-open .offsidebar {
  right: 0; }

/* Transformation ready devices*/
.csstransforms3d .offsidebar {
  right: 0;
  -webkit-transform: translate3d(235px, 0, 0);
  transform: translate3d(235px, 0, 0);
  -webkit-transition: -webkit-transform 0.3s ease, z-index 0s linear 0.3s;
  -moz-transition: -moz-transform 0.3s ease, z-index 0s linear 0.3s;
  -o-transition: -o-transform 0.3s ease, z-index 0s linear 0.3s;
  transition: transform 0.3s ease, z-index 0s linear 0.3s; }

.csstransforms3d .offsidebar-open .offsidebar {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.3s ease;
  -moz-transition: -moz-transform 0.3s ease;
  -o-transition: -o-transform 0.3s ease;
  transition: transform 0.3s ease; }

/* ========================================================================
   Component: breadcrumbs
 ========================================================================== */
.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: #909FA7;
  padding: 10px 20px; }

h3 + .breadcrumb,
.content-heading + .breadcrumb {
  margin: -25px -25px 20px -20px;
  background-color: #fafbfc;
  border-top: 1px solid #cfdbe2;
  border-bottom: 1px solid #cfdbe2; }

h3 > .breadcrumb,
.content-heading > .breadcrumb {
  background: transparent;
  font-size: 13px;
  border: 0;
  padding: 10px 10px 0 0;
  margin-bottom: 0; }
  h3 > .breadcrumb.pull-right,
  .content-heading > .breadcrumb.pull-right {
    margin: -2px 0 0; }

/* ========================================================================
     Component: button-extra
 ========================================================================== */
.btn-inverse {
  color: #fff;
  background-color: #131e26;
  border-color: #131e26; }
  .btn-inverse:focus, .btn-inverse.focus {
    color: #fff;
    background-color: #0c1318;
    border-color: #05090b; }
  .btn-inverse:hover {
    color: #fff;
    background-color: #0c1318;
    border-color: #05090b; }
  .btn-inverse:active, .btn-inverse.active,
  .open > .btn-inverse.dropdown-toggle {
    color: #fff;
    background-color: #0c1318;
    border-color: #05090b; }
    .btn-inverse:active:hover, .btn-inverse:active:focus, .btn-inverse:active.focus, .btn-inverse.active:hover, .btn-inverse.active:focus, .btn-inverse.active.focus,
    .open > .btn-inverse.dropdown-toggle:hover,
    .open > .btn-inverse.dropdown-toggle:focus,
    .open > .btn-inverse.dropdown-toggle.focus {
      color: #fff;
      background-color: #0c1318;
      border-color: #05090b; }
  .btn-inverse:active, .btn-inverse.active,
  .open > .btn-inverse.dropdown-toggle {
    background-image: none; }
  .btn-inverse.disabled:hover, .btn-inverse.disabled:focus, .btn-inverse.disabled.focus, .btn-inverse[disabled]:hover, .btn-inverse[disabled]:focus, .btn-inverse[disabled].focus,
  fieldset[disabled] .btn-inverse:hover,
  fieldset[disabled] .btn-inverse:focus,
  fieldset[disabled] .btn-inverse.focus {
    background-color: #131e26;
    border-color: #131e26; }
  .btn-inverse .badge {
    color: #131e26;
    background-color: #fff; }

.btn-green {
  color: #fff;
  background-color: #37bc9b;
  border-color: transparent; }
  .btn-green:focus, .btn-green.focus {
    color: #fff;
    background-color: #32ac8e;
    border-color: transparent; }
  .btn-green:hover {
    color: #fff;
    background-color: #32ac8e;
    border-color: transparent; }
  .btn-green:active, .btn-green.active,
  .open > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #32ac8e;
    border-color: transparent; }
    .btn-green:active:hover, .btn-green:active:focus, .btn-green:active.focus, .btn-green.active:hover, .btn-green.active:focus, .btn-green.active.focus,
    .open > .btn-green.dropdown-toggle:hover,
    .open > .btn-green.dropdown-toggle:focus,
    .open > .btn-green.dropdown-toggle.focus {
      color: #fff;
      background-color: #32ac8e;
      border-color: transparent; }
  .btn-green:active, .btn-green.active,
  .open > .btn-green.dropdown-toggle {
    background-image: none; }
  .btn-green.disabled:hover, .btn-green.disabled:focus, .btn-green.disabled.focus, .btn-green[disabled]:hover, .btn-green[disabled]:focus, .btn-green[disabled].focus,
  fieldset[disabled] .btn-green:hover,
  fieldset[disabled] .btn-green:focus,
  fieldset[disabled] .btn-green.focus {
    background-color: #37bc9b;
    border-color: transparent; }
  .btn-green .badge {
    color: #37bc9b;
    background-color: #fff; }

.btn-purple {
  color: #fff;
  background-color: #7266ba;
  border-color: transparent; }
  .btn-purple:focus, .btn-purple.focus {
    color: #fff;
    background-color: #6558b4;
    border-color: transparent; }
  .btn-purple:hover {
    color: #fff;
    background-color: #6558b4;
    border-color: transparent; }
  .btn-purple:active, .btn-purple.active,
  .open > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #6558b4;
    border-color: transparent; }
    .btn-purple:active:hover, .btn-purple:active:focus, .btn-purple:active.focus, .btn-purple.active:hover, .btn-purple.active:focus, .btn-purple.active.focus,
    .open > .btn-purple.dropdown-toggle:hover,
    .open > .btn-purple.dropdown-toggle:focus,
    .open > .btn-purple.dropdown-toggle.focus {
      color: #fff;
      background-color: #6558b4;
      border-color: transparent; }
  .btn-purple:active, .btn-purple.active,
  .open > .btn-purple.dropdown-toggle {
    background-image: none; }
  .btn-purple.disabled:hover, .btn-purple.disabled:focus, .btn-purple.disabled.focus, .btn-purple[disabled]:hover, .btn-purple[disabled]:focus, .btn-purple[disabled].focus,
  fieldset[disabled] .btn-purple:hover,
  fieldset[disabled] .btn-purple:focus,
  fieldset[disabled] .btn-purple.focus {
    background-color: #7266ba;
    border-color: transparent; }
  .btn-purple .badge {
    color: #7266ba;
    background-color: #fff; }

.btn-pink {
  color: #fff;
  background-color: #f532e5;
  border-color: transparent; }
  .btn-pink:focus, .btn-pink.focus {
    color: #fff;
    background-color: #f41fe3;
    border-color: transparent; }
  .btn-pink:hover {
    color: #fff;
    background-color: #f41fe3;
    border-color: transparent; }
  .btn-pink:active, .btn-pink.active,
  .open > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #f41fe3;
    border-color: transparent; }
    .btn-pink:active:hover, .btn-pink:active:focus, .btn-pink:active.focus, .btn-pink.active:hover, .btn-pink.active:focus, .btn-pink.active.focus,
    .open > .btn-pink.dropdown-toggle:hover,
    .open > .btn-pink.dropdown-toggle:focus,
    .open > .btn-pink.dropdown-toggle.focus {
      color: #fff;
      background-color: #f41fe3;
      border-color: transparent; }
  .btn-pink:active, .btn-pink.active,
  .open > .btn-pink.dropdown-toggle {
    background-image: none; }
  .btn-pink.disabled:hover, .btn-pink.disabled:focus, .btn-pink.disabled.focus, .btn-pink[disabled]:hover, .btn-pink[disabled]:focus, .btn-pink[disabled].focus,
  fieldset[disabled] .btn-pink:hover,
  fieldset[disabled] .btn-pink:focus,
  fieldset[disabled] .btn-pink.focus {
    background-color: #f532e5;
    border-color: transparent; }
  .btn-pink .badge {
    color: #f532e5;
    background-color: #fff; }

.btn-yellow {
  color: #fff;
  background-color: #fad732;
  border-color: transparent; }
  .btn-yellow:focus, .btn-yellow.focus {
    color: #fff;
    background-color: #fad31e;
    border-color: transparent; }
  .btn-yellow:hover {
    color: #fff;
    background-color: #fad31e;
    border-color: transparent; }
  .btn-yellow:active, .btn-yellow.active,
  .open > .btn-yellow.dropdown-toggle {
    color: #fff;
    background-color: #fad31e;
    border-color: transparent; }
    .btn-yellow:active:hover, .btn-yellow:active:focus, .btn-yellow:active.focus, .btn-yellow.active:hover, .btn-yellow.active:focus, .btn-yellow.active.focus,
    .open > .btn-yellow.dropdown-toggle:hover,
    .open > .btn-yellow.dropdown-toggle:focus,
    .open > .btn-yellow.dropdown-toggle.focus {
      color: #fff;
      background-color: #fad31e;
      border-color: transparent; }
  .btn-yellow:active, .btn-yellow.active,
  .open > .btn-yellow.dropdown-toggle {
    background-image: none; }
  .btn-yellow.disabled:hover, .btn-yellow.disabled:focus, .btn-yellow.disabled.focus, .btn-yellow[disabled]:hover, .btn-yellow[disabled]:focus, .btn-yellow[disabled].focus,
  fieldset[disabled] .btn-yellow:hover,
  fieldset[disabled] .btn-yellow:focus,
  fieldset[disabled] .btn-yellow.focus {
    background-color: #fad732;
    border-color: transparent; }
  .btn-yellow .badge {
    color: #fad732;
    background-color: #fff; }

.btn-outline {
  background-color: transparent;
  border-color: #fff; }
  .btn-outline:hover, .btn-outline:focus {
    background-color: #fff;
    color: #5d9cec; }

.btn-flat {
  border-bottom-width: 1px;
  border-radius: 0;
  box-shadow: 0 0 0 #000; }

.btn-xl {
  padding: 20px 16px;
  font-size: 18px; }

.btn-square {
  border-radius: 0; }

.btn-pill-left, .btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px; }

.btn-pill-right, .btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px; }

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0; }

.btn-label {
  position: relative;
  background: transparent;
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 6px 16px;
  left: -16px;
  border-radius: 3px 0 0 3px; }
  .btn-label.btn-label-right {
    left: auto;
    right: -16px;
    border-radius: 0 3px 3px 0; }

.btn-lg .btn-label {
  padding: 10px 20px;
  left: -20px;
  border-radius: 5px 0 0 5px; }
  .btn-lg .btn-label.btn-label-right {
    left: auto;
    right: -20px;
    border-radius: 0 5px 5px 0; }

.btn-sm .btn-label, .calendar-app .fc-button .btn-label {
  padding: 5px 10px;
  left: -10px;
  border-radius: 2px 0 0 2px; }
  .btn-sm .btn-label.btn-label-right, .calendar-app .fc-button .btn-label.btn-label-right {
    left: auto;
    right: -10px;
    border-radius: 0 2px 2px 0; }

.btn-xs .btn-label {
  padding: 1px 5px;
  left: -5px;
  border-radius: 2px 0 0 2px; }
  .btn-xs .btn-label.btn-label-right {
    left: auto;
    right: -5px;
    border-radius: 0 2px 2px 0; }

.btn-fw {
  min-width: 80px; }
  .btn-fw.btn-sm, .calendar-app .btn-fw.fc-button {
    min-width: 40px; }
  .btn-fw.btn-md {
    min-width: 60px; }
  .btn-fw.btn-lg {
    min-width: 140px; }

.btn-circle {
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 12px;
  line-height: 35px;
  border-radius: 500px;
  padding: 0;
  border: 0; }
  .btn-circle > * {
    line-height: inherit !important; }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    font-size: 18px;
    line-height: 50px; }

.btn-flex {
  display: inline-flex;
  align-items: center; }

.btn.btn-info a, .ColVis .btn-info.ColVis_Button a {
  color: #fff; }

.btn.btn-edit--modal, .ColVis .btn-edit--modal.ColVis_Button {
  padding: 0; }

.btn.btn-edit--modal a, .ColVis .btn-edit--modal.ColVis_Button a {
  display: block;
  padding: 6px 12px; }

/* ========================================================================
     Component: placeholder
 ========================================================================== */
.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed #ddd;
  background: #fafafa;
  color: #444; }

.box-placeholder > :last-child {
  margin-bottom: 0; }

.box-placeholder-lg {
  padding-top: 80px;
  padding-bottom: 80px; }

/* ========================================================================
     Component: panels
 ========================================================================== */
.panel .table {
  margin-bottom: 0;
  border: 0; }

.panel.panel-transparent {
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0 #000; }
  .panel.panel-transparent .panel-heading,
  .panel.panel-transparent .panel-body {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0; }

.panel-flat {
  margin: 0 !important;
  border: 0; }

.panel-group .panel-flat {
  box-shadow: 0 0 0 #000; }
  .panel-group .panel-flat:first-child {
    border-radius: 4px 4px 0 0; }
  .panel-group .panel-flat:last-child {
    border-radius: 0 0 4px 4px; }

.panel-heading > a[data-tool] {
  display: inline-block;
  color: #fff;
  width: 2em;
  text-align: center; }

.panel-default .panel-heading > a[data-tool] {
  color: #c1c2c3; }

.panel-heading > .label.pull-right {
  margin-top: 3px; }
  .panel-heading > .label.pull-right + .label.pull-right {
    margin-right: 10px; }

.panel-heading.panel-heading-collapsed {
  border-radius: 3px; }

.panel-footer .pagination {
  margin: 0; }

.panel-footer .radial-bar {
  margin-bottom: 0; }

.panel-footer p {
  margin-bottom: 0; }

.panel-heading-active {
  background-color: #00ad4d !important;
  font-weight: bold;
  font-size: 16px; }
  .panel-heading-active > a {
    padding: 15px 0;
    color: #fff !important;
    -webkit-transition: padding 0.5s ease;
    -o-transition: padding 0.5s ease;
    transition: padding 0.5s ease; }
  .panel-heading-active + .panel-collapse:after {
    content: "";
    display: block;
    width: 60%;
    margin: 0 auto;
    height: 0;
    border-bottom: 1px solid #e1e2e3; }

.panel-inverse {
  border-color: #cfdbe2; }
  .panel-inverse > .panel-heading {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
    .panel-inverse > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-inverse > .panel-heading .badge {
      color: #131e26;
      background-color: #fff; }
  .panel-inverse > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-green {
  border-color: #cfdbe2; }
  .panel-green > .panel-heading {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
    .panel-green > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-green > .panel-heading .badge {
      color: #37bc9b;
      background-color: #fff; }
  .panel-green > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-pink {
  border-color: #cfdbe2; }
  .panel-pink > .panel-heading {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
    .panel-pink > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-pink > .panel-heading .badge {
      color: #f532e5;
      background-color: #fff; }
  .panel-pink > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-purple {
  border-color: #cfdbe2; }
  .panel-purple > .panel-heading {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
    .panel-purple > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-purple > .panel-heading .badge {
      color: #7266ba;
      background-color: #fff; }
  .panel-purple > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-dark {
  border-color: #cfdbe2; }
  .panel-dark > .panel-heading {
    color: #fff;
    background-color: #3a3f51;
    border-color: #3a3f51; }
    .panel-dark > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-dark > .panel-heading .badge {
      color: #3a3f51;
      background-color: #fff; }
  .panel-dark > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

/* ========================================================================
     Component: progress-extra
 ========================================================================== */
.progress-sm {
  height: 15px; }

.progress-xs {
  height: 8px; }

.progress-bar-purple {
  background-color: #7266ba; }
  .progress-striped .progress-bar-purple {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-inverse {
  background-color: #131e26; }
  .progress-striped .progress-bar-inverse {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-green {
  background-color: #37bc9b; }
  .progress-striped .progress-bar-green {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-pink {
  background-color: #f532e5; }
  .progress-striped .progress-bar-pink {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-yellow {
  background-color: #fad732; }
  .progress-striped .progress-bar-yellow {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-1 {
  width: 1%; }

.progress-2 {
  width: 2%; }

.progress-3 {
  width: 3%; }

.progress-4 {
  width: 4%; }

.progress-5 {
  width: 5%; }

.progress-6 {
  width: 6%; }

.progress-7 {
  width: 7%; }

.progress-8 {
  width: 8%; }

.progress-9 {
  width: 9%; }

.progress-10 {
  width: 10%; }

.progress-11 {
  width: 11%; }

.progress-12 {
  width: 12%; }

.progress-13 {
  width: 13%; }

.progress-14 {
  width: 14%; }

.progress-15 {
  width: 15%; }

.progress-16 {
  width: 16%; }

.progress-17 {
  width: 17%; }

.progress-18 {
  width: 18%; }

.progress-19 {
  width: 19%; }

.progress-20 {
  width: 20%; }

.progress-21 {
  width: 21%; }

.progress-22 {
  width: 22%; }

.progress-23 {
  width: 23%; }

.progress-24 {
  width: 24%; }

.progress-25 {
  width: 25%; }

.progress-26 {
  width: 26%; }

.progress-27 {
  width: 27%; }

.progress-28 {
  width: 28%; }

.progress-29 {
  width: 29%; }

.progress-30 {
  width: 30%; }

.progress-31 {
  width: 31%; }

.progress-32 {
  width: 32%; }

.progress-33 {
  width: 33%; }

.progress-34 {
  width: 34%; }

.progress-35 {
  width: 35%; }

.progress-36 {
  width: 36%; }

.progress-37 {
  width: 37%; }

.progress-38 {
  width: 38%; }

.progress-39 {
  width: 39%; }

.progress-40 {
  width: 40%; }

.progress-41 {
  width: 41%; }

.progress-42 {
  width: 42%; }

.progress-43 {
  width: 43%; }

.progress-44 {
  width: 44%; }

.progress-45 {
  width: 45%; }

.progress-46 {
  width: 46%; }

.progress-47 {
  width: 47%; }

.progress-48 {
  width: 48%; }

.progress-49 {
  width: 49%; }

.progress-50 {
  width: 50%; }

.progress-51 {
  width: 51%; }

.progress-52 {
  width: 52%; }

.progress-53 {
  width: 53%; }

.progress-54 {
  width: 54%; }

.progress-55 {
  width: 55%; }

.progress-56 {
  width: 56%; }

.progress-57 {
  width: 57%; }

.progress-58 {
  width: 58%; }

.progress-59 {
  width: 59%; }

.progress-60 {
  width: 60%; }

.progress-61 {
  width: 61%; }

.progress-62 {
  width: 62%; }

.progress-63 {
  width: 63%; }

.progress-64 {
  width: 64%; }

.progress-65 {
  width: 65%; }

.progress-66 {
  width: 66%; }

.progress-67 {
  width: 67%; }

.progress-68 {
  width: 68%; }

.progress-69 {
  width: 69%; }

.progress-70 {
  width: 70%; }

.progress-71 {
  width: 71%; }

.progress-72 {
  width: 72%; }

.progress-73 {
  width: 73%; }

.progress-74 {
  width: 74%; }

.progress-75 {
  width: 75%; }

.progress-76 {
  width: 76%; }

.progress-77 {
  width: 77%; }

.progress-78 {
  width: 78%; }

.progress-79 {
  width: 79%; }

.progress-80 {
  width: 80%; }

.progress-81 {
  width: 81%; }

.progress-82 {
  width: 82%; }

.progress-83 {
  width: 83%; }

.progress-84 {
  width: 84%; }

.progress-85 {
  width: 85%; }

.progress-86 {
  width: 86%; }

.progress-87 {
  width: 87%; }

.progress-88 {
  width: 88%; }

.progress-89 {
  width: 89%; }

.progress-90 {
  width: 90%; }

.progress-91 {
  width: 91%; }

.progress-92 {
  width: 92%; }

.progress-93 {
  width: 93%; }

.progress-94 {
  width: 94%; }

.progress-95 {
  width: 95%; }

.progress-96 {
  width: 96%; }

.progress-97 {
  width: 97%; }

.progress-98 {
  width: 98%; }

.progress-99 {
  width: 99%; }

.progress-100 {
  width: 100%; }

/* ========================================================================
     Component: radial-bar
 ========================================================================== */
/* -------------------------------------
 * Bar container
 * ------------------------------------- */
.radial-bar {
  position: relative;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  border: 2px solid #fafafa;
  background-color: transparent;
  margin-bottom: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px; }
  .radial-bar:after,
  .radial-bar > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    color: #a1a2a3; }
  .radial-bar:after {
    content: attr(data-label);
    background-color: #fff;
    z-index: 101; }
  .radial-bar > img {
    z-index: 102; }
  .radial-bar:after,
  .radial-bar > img {
    width: 56px;
    height: 56px;
    margin-left: 12px;
    margin-top: 12px;
    line-height: 56px; }
  .radial-bar.radial-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-50 {
    background-image: linear-gradient(-90deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-55 {
    background-image: linear-gradient(-72deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-60 {
    background-image: linear-gradient(-54deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-65 {
    background-image: linear-gradient(-36deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-70 {
    background-image: linear-gradient(-18deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-75 {
    background-image: linear-gradient(0deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-80 {
    background-image: linear-gradient(18deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-85 {
    background-image: linear-gradient(36deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-90 {
    background-image: linear-gradient(54deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-95 {
    background-image: linear-gradient(72deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-100 {
    background-image: linear-gradient(90deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-50 {
  background-image: linear-gradient(-90deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-55 {
  background-image: linear-gradient(-72deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-60 {
  background-image: linear-gradient(-54deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-65 {
  background-image: linear-gradient(-36deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-70 {
  background-image: linear-gradient(-18deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-75 {
  background-image: linear-gradient(0deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-80 {
  background-image: linear-gradient(18deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-85 {
  background-image: linear-gradient(36deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-90 {
  background-image: linear-gradient(54deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-95 {
  background-image: linear-gradient(72deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-100 {
  background-image: linear-gradient(90deg, #5d9cec 50%, transparent 50%, transparent), linear-gradient(270deg, #5d9cec 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-50 {
  background-image: linear-gradient(-90deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-55 {
  background-image: linear-gradient(-72deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-60 {
  background-image: linear-gradient(-54deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-65 {
  background-image: linear-gradient(-36deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-70 {
  background-image: linear-gradient(-18deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-75 {
  background-image: linear-gradient(0deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-80 {
  background-image: linear-gradient(18deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-85 {
  background-image: linear-gradient(36deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-90 {
  background-image: linear-gradient(54deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-95 {
  background-image: linear-gradient(72deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-100 {
  background-image: linear-gradient(90deg, #00ad4d 50%, transparent 50%, transparent), linear-gradient(270deg, #00ad4d 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-50 {
  background-image: linear-gradient(-90deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-55 {
  background-image: linear-gradient(-72deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-60 {
  background-image: linear-gradient(-54deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-65 {
  background-image: linear-gradient(-36deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-70 {
  background-image: linear-gradient(-18deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-75 {
  background-image: linear-gradient(0deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-80 {
  background-image: linear-gradient(18deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-85 {
  background-image: linear-gradient(36deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-90 {
  background-image: linear-gradient(54deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-95 {
  background-image: linear-gradient(72deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-100 {
  background-image: linear-gradient(90deg, #23b7e5 50%, transparent 50%, transparent), linear-gradient(270deg, #23b7e5 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-50 {
  background-image: linear-gradient(-90deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-55 {
  background-image: linear-gradient(-72deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-60 {
  background-image: linear-gradient(-54deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-65 {
  background-image: linear-gradient(-36deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-70 {
  background-image: linear-gradient(-18deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-75 {
  background-image: linear-gradient(0deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-80 {
  background-image: linear-gradient(18deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-85 {
  background-image: linear-gradient(36deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-90 {
  background-image: linear-gradient(54deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-95 {
  background-image: linear-gradient(72deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-100 {
  background-image: linear-gradient(90deg, #ff902b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff902b 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-50 {
  background-image: linear-gradient(-90deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-55 {
  background-image: linear-gradient(-72deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-60 {
  background-image: linear-gradient(-54deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-65 {
  background-image: linear-gradient(-36deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-70 {
  background-image: linear-gradient(-18deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-75 {
  background-image: linear-gradient(0deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-80 {
  background-image: linear-gradient(18deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-85 {
  background-image: linear-gradient(36deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-90 {
  background-image: linear-gradient(54deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-95 {
  background-image: linear-gradient(72deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-100 {
  background-image: linear-gradient(90deg, #f05050 50%, transparent 50%, transparent), linear-gradient(270deg, #f05050 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-50 {
  background-image: linear-gradient(-90deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-55 {
  background-image: linear-gradient(-72deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-60 {
  background-image: linear-gradient(-54deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-65 {
  background-image: linear-gradient(-36deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-70 {
  background-image: linear-gradient(-18deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-75 {
  background-image: linear-gradient(0deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-80 {
  background-image: linear-gradient(18deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-85 {
  background-image: linear-gradient(36deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-90 {
  background-image: linear-gradient(54deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-95 {
  background-image: linear-gradient(72deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-neutral.radial-bar-100 {
  background-image: linear-gradient(90deg, #3a3f51 50%, transparent 50%, transparent), linear-gradient(270deg, #3a3f51 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-50 {
  background-image: linear-gradient(-90deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-55 {
  background-image: linear-gradient(-72deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-60 {
  background-image: linear-gradient(-54deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-65 {
  background-image: linear-gradient(-36deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-70 {
  background-image: linear-gradient(-18deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-75 {
  background-image: linear-gradient(0deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-80 {
  background-image: linear-gradient(18deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-85 {
  background-image: linear-gradient(36deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-90 {
  background-image: linear-gradient(54deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-95 {
  background-image: linear-gradient(72deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-not-pointing.radial-bar-100 {
  background-image: linear-gradient(90deg, #dde6e9 50%, transparent 50%, transparent), linear-gradient(270deg, #dde6e9 50%, #fafafa 50%, #fafafa); }

.radial-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 22px; }
  .radial-bar-lg:after,
  .radial-bar-lg > img {
    width: 70px;
    height: 70px;
    margin-left: 15px;
    margin-top: 15px;
    line-height: 70px; }

.radial-bar-sm {
  width: 50px;
  height: 50px;
  font-size: 12px; }
  .radial-bar-sm:after,
  .radial-bar-sm > img {
    width: 35px;
    height: 35px;
    margin-left: 7.5px;
    margin-top: 7.5px;
    line-height: 35px; }

.radial-bar-xs {
  width: 30px;
  height: 30px;
  font-size: 8px; }
  .radial-bar-xs:after,
  .radial-bar-xs > img {
    width: 21px;
    height: 21px;
    margin-left: 4.5px;
    margin-top: 4.5px;
    line-height: 21px; }

x:-o-prefocus, .radial-bar {
  background-clip: content-box; }

/* ========================================================================
     Component: user-block
 ========================================================================== */
.has-user-block {
  display: block;
  overflow: hidden;
  border: 0 !important;
  width: 100% !important; }

.user-block {
  position: relative;
  padding: 25px 0 10px;
  cursor: pointer; }
  .user-block:before, .user-block:after {
    content: " ";
    display: table; }
  .user-block:after {
    clear: both; }
  .user-block > .user-block-picture {
    position: relative;
    width: 60px;
    margin: 0 auto; }
    .user-block > .user-block-picture > img {
      max-width: 100%;
      height: auto; }
  .user-block .user-block-info {
    padding-top: 15px;
    text-align: center;
    white-space: nowrap; }
    .user-block .user-block-info .user-block-name,
    .user-block .user-block-info .user-block-role {
      display: block; }
    .user-block .user-block-info .user-block-name {
      color: #7D848F; }
    .user-block .user-block-info .user-block-role {
      font-size: 12px;
      color: #aaa; }

.user-block-status {
  position: relative; }
  .user-block-status > .circle {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff; }

.aside-collapsed .user-block {
  padding: 15px 0 14px;
  margin: 0;
  text-align: center; }
  .aside-collapsed .user-block > .user-block-picture {
    float: none;
    margin: 0  auto;
    width: 50px; }
    .aside-collapsed .user-block > .user-block-picture > .user-block-status {
      display: block; }
  .aside-collapsed .user-block .user-block-info {
    display: none; }

/* ========================================================================
     Component: circles
 ========================================================================== */
.circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 500px;
  margin: 0 .5em;
  background-color: #ddd;
  vertical-align: baseline;
  border: 2px solid transparent; }
  .circle.text-left {
    margin-left: 0; }
  .circle.text-right {
    margin-right: 0; }

.circle-primary {
  background-color: #5d9cec; }

.circle-success {
  background-color: #00ad4d; }

.circle-info {
  background-color: #23b7e5; }

.circle-warning {
  background-color: #ff902b; }

.circle-danger {
  background-color: #f05050; }

.circle-green {
  background-color: #37bc9b; }

.circle-pink {
  background-color: #f532e5; }

.circle-inverse {
  background-color: #131e26; }

.circle-purple {
  background-color: #7266ba; }

.circle-sm {
  width: 5px;
  height: 5px; }

.circle-lg {
  width: 11px;
  height: 11px; }

.circle-xl {
  width: 18px;
  height: 18px; }

.circle-outline {
  background-color: transparent; }
  .circle-outline.circle-primary {
    border: 1px solid #5d9cec; }
  .circle-outline.circle-success {
    border: 1px solid #00ad4d; }
  .circle-outline.circle-info {
    border: 1px solid #23b7e5; }
  .circle-outline.circle-warning {
    border: 1px solid #ff902b; }
  .circle-outline.circle-danger {
    border: 1px solid #f05050; }

/* ========================================================================
     Component: timeline
 ========================================================================== */
.timeline,
.timeline-alt {
  list-style: none;
  padding: 0 0 20px;
  position: relative;
  z-index: 0; }
  .timeline:before,
  .timeline-alt:before {
    position: absolute;
    top: 0;
    bottom: 0;
    content: " ";
    width: 1px;
    background-color: #d1d2d3;
    left: 18px; }
  .timeline .timeline-end,
  .timeline-alt .timeline-end {
    float: none;
    clear: both; }
  .timeline .popover.left > .arrow:after,
  .timeline-alt .popover.left > .arrow:after {
    border-left-color: #fff; }
  .timeline .popover.right > .arrow:after,
  .timeline-alt .popover.right > .arrow:after {
    border-right-color: #fff; }
  .timeline .popover.left > .popover-title + .arrow:after,
  .timeline-alt .popover.left > .popover-title + .arrow:after {
    border-left-color: #fafafa; }
  .timeline .popover.right > .popover-title + .arrow:after,
  .timeline-alt .popover.right > .popover-title + .arrow:after {
    border-right-color: #fafafa; }
  .timeline > li,
  .timeline-alt > li {
    margin-bottom: 20px; }
    .timeline > li:before, .timeline > li:after,
    .timeline-alt > li:before,
    .timeline-alt > li:after {
      content: " ";
      display: table; }
    .timeline > li:after,
    .timeline-alt > li:after {
      clear: both; }
    .timeline > li.timeline-separator,
    .timeline-alt > li.timeline-separator {
      position: relative;
      float: none;
      clear: both;
      margin: 0 auto;
      padding: 40px 0; }
      .timeline > li.timeline-separator:first-child,
      .timeline-alt > li.timeline-separator:first-child {
        padding-top: 0; }
      .timeline > li.timeline-separator:before,
      .timeline-alt > li.timeline-separator:before {
        content: attr(data-datetime);
        display: inline-block;
        width: 120px;
        text-align: center;
        padding: 0 20px;
        line-height: 30px;
        background-color: #b6c5da;
        color: #fff; }
  .timeline > li > .timeline-panel,
  .timeline-alt > li > .timeline-panel {
    margin-left: 60px; }
    .timeline > li > .timeline-panel .popover,
    .timeline-alt > li > .timeline-panel .popover {
      position: relative;
      display: block;
      margin: 0;
      width: 100%;
      max-width: none; }
      .timeline > li > .timeline-panel .popover .arrow,
      .timeline-alt > li > .timeline-panel .popover .arrow {
        top: 18px;
        display: none; }
      .timeline > li > .timeline-panel .popover .popover-content .popover-title,
      .timeline-alt > li > .timeline-panel .popover .popover-content .popover-title {
        background-color: transparent;
        border: 0;
        padding-left: 0;
        padding-top: 0; }
  .timeline > li > .timeline-date,
  .timeline-alt > li > .timeline-date {
    margin: -20px 0 0;
    display: block;
    height: 20px;
    line-height: 20px;
    font-size: 13px; }
    .timeline > li > .timeline-date > time:after,
    .timeline-alt > li > .timeline-date > time:after {
      content: attr(datetime); }
  .timeline > li > .timeline-badge,
  .timeline-alt > li > .timeline-badge {
    position: absolute;
    left: 0;
    width: 36px;
    height: 36px;
    border-radius: 500px;
    line-height: 36px;
    font-size: 1em;
    text-align: center;
    color: #fff;
    background-color: #999;
    z-index: 100; }
    .timeline > li > .timeline-badge.primary,
    .timeline-alt > li > .timeline-badge.primary {
      background-color: #5d9cec; }
    .timeline > li > .timeline-badge.success,
    .timeline-alt > li > .timeline-badge.success {
      background-color: #00ad4d; }
    .timeline > li > .timeline-badge.warning,
    .timeline-alt > li > .timeline-badge.warning {
      background-color: #ff902b; }
    .timeline > li > .timeline-badge.danger,
    .timeline-alt > li > .timeline-badge.danger {
      background-color: #f05050; }
    .timeline > li > .timeline-badge.info,
    .timeline-alt > li > .timeline-badge.info {
      background-color: #23b7e5; }
    .timeline > li > .timeline-badge.inverse,
    .timeline-alt > li > .timeline-badge.inverse {
      background-color: #131e26; }
    .timeline > li > .timeline-badge.green,
    .timeline-alt > li > .timeline-badge.green {
      background-color: #37bc9b; }
    .timeline > li > .timeline-badge.pink,
    .timeline-alt > li > .timeline-badge.pink {
      background-color: #f532e5; }
    .timeline > li > .timeline-badge.purple,
    .timeline-alt > li > .timeline-badge.purple {
      background-color: #7266ba; }
    .timeline > li > .timeline-badge.dark,
    .timeline-alt > li > .timeline-badge.dark {
      background-color: #3a3f51; }
    .timeline > li > .timeline-badge.yellow,
    .timeline-alt > li > .timeline-badge.yellow {
      background-color: #fad732; }

.timeline-alt .popover .arrow {
  display: block !important; }

.timeline-title {
  margin-top: 0;
  color: inherit; }

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0; }

.timeline-body > p + p {
  margin-top: 5px; }

@media only screen and (min-width: 992px) {
  .timeline:before {
    left: 50%;
    margin-left: 18px; }
  .timeline > li {
    float: left;
    clear: left;
    width: 45%; }
    .timeline > li.timeline-inverted {
      float: right;
      clear: right;
      margin-top: 50px; }
  .timeline > li.timeline-separator {
    text-align: center; }
    .timeline > li.timeline-separator:before {
      margin-left: 36px; }
  .timeline > li > .timeline-badge {
    left: 50%;
    margin-left: 0; }
  .timeline > li > .timeline-date {
    float: none;
    position: absolute;
    width: 40%;
    left: 50%;
    top: 18px;
    margin-left: 54px;
    margin-top: -10px; }
  .timeline > li > .timeline-panel {
    margin: 0; }
    .timeline > li > .timeline-panel:before {
      border-left-width: 15px;
      border-right-width: 0;
      top: 26px;
      right: -15px;
      left: auto; }
    .timeline > li > .timeline-panel:after {
      border-left-width: 14px;
      border-right-width: 0;
      top: 27px;
      right: -14px;
      left: auto; }
    .timeline > li > .timeline-panel .popover .arrow {
      display: block; }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding-left: 36px; }
  .timeline > li.timeline-inverted .timeline-date {
    left: auto;
    right: 50%;
    width: auto;
    margin-left: 0;
    margin-right: 36px; } }

/* ========================================================================
     Component: dropdown-extras
 ========================================================================== */
.dropdown-lg > .dropdown-menu {
  min-width: 200px; }

.dropdown-list > .dropdown-menu {
  padding: 0;
  min-width: 220px; }

.dropdown-list .list-group {
  margin: 0; }

.dropdown-list .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0; }
  .dropdown-list .list-group-item:first-child {
    border-top: 0; }
  .dropdown-list .list-group-item:last-child {
    border-bottom: 0; }

.dropdown > a {
  position: relative; }
  .dropdown > a > .label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px; }
    @media only screen and (min-width: 768px) {
      .dropdown > a > .label {
        top: 10px; } }

.dropdown-menu-header {
  padding: 10px 15px;
  background-color: #fafafa;
  border-bottom: 1px solid #e1e1e1; }

/* ========================================================================
     Component: row-extra
 ========================================================================== */
.container-sm {
  max-width: 750px;
  width: auto; }

.container-md {
  max-width: 970px;
  width: auto; }

.container-lg {
  max-width: 1170px;
  width: auto; }

.row-table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0; }
  .row-table > [class*="col-"] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle; }

.row-flush > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

/* ========================================================================
     Component: half-float
 ========================================================================== */
.half-float {
  position: relative;
  margin-bottom: 65px; }
  .half-float .half-float-bottom,
  .half-float .half-float-top {
    position: absolute;
    left: 50%;
    bottom: -60px;
    width: 120px;
    height: 120px;
    margin-left: -60px;
    z-index: 2; }
  .half-float .half-float-top {
    bottom: auto;
    top: -60px; }
  .half-float + * {
    margin-top: -55px;
    padding-top: 65px; }

/* ========================================================================
     Component: masonry-grid
 ========================================================================== */
.row-masonry {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%; }

.row-masonry > .col-masonry {
  display: inline-block;
  width: 100%;
  min-height: 1em;
  margin-bottom: 15px; }

/* Columns definition by devices */
@media only screen and (min-width: 480px) {
  .row-masonry-sm-1 {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 1;
    -webkit-columns: 1;
    columns: 1; }
  .no-csscolumns .row-masonry-sm-1 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-1:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-1 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 99%; }
  .row-masonry-sm-2 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 2;
    -webkit-columns: 2;
    columns: 2; }
  .no-csscolumns .row-masonry-sm-2 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-2:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-2 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 49%; }
  .row-masonry-sm-3 {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 3;
    -webkit-columns: 3;
    columns: 3; }
  .no-csscolumns .row-masonry-sm-3 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-3:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-3 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 32%; }
  .row-masonry-sm-4 {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 4;
    -webkit-columns: 4;
    columns: 4; }
  .no-csscolumns .row-masonry-sm-4 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-4:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-4 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 24%; }
  .row-masonry-sm-5 {
    -moz-column-count: 5;
    -webkit-column-count: 5;
    column-count: 5;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 5;
    -webkit-columns: 5;
    columns: 5; }
  .no-csscolumns .row-masonry-sm-5 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-5:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-5 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 19%; }
  .row-masonry-sm-6 {
    -moz-column-count: 6;
    -webkit-column-count: 6;
    column-count: 6;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 6;
    -webkit-columns: 6;
    columns: 6; }
  .no-csscolumns .row-masonry-sm-6 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-6:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-6 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 15%; }
  .row-masonry-sm-7 {
    -moz-column-count: 7;
    -webkit-column-count: 7;
    column-count: 7;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 7;
    -webkit-columns: 7;
    columns: 7; }
  .no-csscolumns .row-masonry-sm-7 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-7:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-7 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 13%; }
  .row-masonry-sm-8 {
    -moz-column-count: 8;
    -webkit-column-count: 8;
    column-count: 8;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 8;
    -webkit-columns: 8;
    columns: 8; }
  .no-csscolumns .row-masonry-sm-8 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-8:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-8 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 11%; }
  .row-masonry-sm-9 {
    -moz-column-count: 9;
    -webkit-column-count: 9;
    column-count: 9;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 9;
    -webkit-columns: 9;
    columns: 9; }
  .no-csscolumns .row-masonry-sm-9 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-9:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-9 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 10%; }
  .row-masonry-sm-10 {
    -moz-column-count: 10;
    -webkit-column-count: 10;
    column-count: 10;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 10;
    -webkit-columns: 10;
    columns: 10; }
  .no-csscolumns .row-masonry-sm-10 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-10:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-10 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 9%; }
  .row-masonry-sm-11 {
    -moz-column-count: 11;
    -webkit-column-count: 11;
    column-count: 11;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 11;
    -webkit-columns: 11;
    columns: 11; }
  .no-csscolumns .row-masonry-sm-11 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-11:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-11 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 8%; }
  .row-masonry-sm-12 {
    -moz-column-count: 12;
    -webkit-column-count: 12;
    column-count: 12;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 12;
    -webkit-columns: 12;
    columns: 12; }
  .no-csscolumns .row-masonry-sm-12 {
    text-align: justify; }
  .no-csscolumns .row-masonry-sm-12:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-sm-12 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 7%; } }

@media only screen and (min-width: 768px) {
  .row-masonry-md-1 {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 1;
    -webkit-columns: 1;
    columns: 1; }
  .no-csscolumns .row-masonry-md-1 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-1:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-1 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 99%; }
  .row-masonry-md-2 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 2;
    -webkit-columns: 2;
    columns: 2; }
  .no-csscolumns .row-masonry-md-2 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-2:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-2 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 49%; }
  .row-masonry-md-3 {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 3;
    -webkit-columns: 3;
    columns: 3; }
  .no-csscolumns .row-masonry-md-3 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-3:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-3 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 32%; }
  .row-masonry-md-4 {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 4;
    -webkit-columns: 4;
    columns: 4; }
  .no-csscolumns .row-masonry-md-4 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-4:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-4 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 24%; }
  .row-masonry-md-5 {
    -moz-column-count: 5;
    -webkit-column-count: 5;
    column-count: 5;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 5;
    -webkit-columns: 5;
    columns: 5; }
  .no-csscolumns .row-masonry-md-5 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-5:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-5 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 19%; }
  .row-masonry-md-6 {
    -moz-column-count: 6;
    -webkit-column-count: 6;
    column-count: 6;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 6;
    -webkit-columns: 6;
    columns: 6; }
  .no-csscolumns .row-masonry-md-6 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-6:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-6 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 15%; }
  .row-masonry-md-7 {
    -moz-column-count: 7;
    -webkit-column-count: 7;
    column-count: 7;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 7;
    -webkit-columns: 7;
    columns: 7; }
  .no-csscolumns .row-masonry-md-7 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-7:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-7 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 13%; }
  .row-masonry-md-8 {
    -moz-column-count: 8;
    -webkit-column-count: 8;
    column-count: 8;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 8;
    -webkit-columns: 8;
    columns: 8; }
  .no-csscolumns .row-masonry-md-8 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-8:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-8 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 11%; }
  .row-masonry-md-9 {
    -moz-column-count: 9;
    -webkit-column-count: 9;
    column-count: 9;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 9;
    -webkit-columns: 9;
    columns: 9; }
  .no-csscolumns .row-masonry-md-9 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-9:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-9 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 10%; }
  .row-masonry-md-10 {
    -moz-column-count: 10;
    -webkit-column-count: 10;
    column-count: 10;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 10;
    -webkit-columns: 10;
    columns: 10; }
  .no-csscolumns .row-masonry-md-10 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-10:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-10 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 9%; }
  .row-masonry-md-11 {
    -moz-column-count: 11;
    -webkit-column-count: 11;
    column-count: 11;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 11;
    -webkit-columns: 11;
    columns: 11; }
  .no-csscolumns .row-masonry-md-11 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-11:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-11 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 8%; }
  .row-masonry-md-12 {
    -moz-column-count: 12;
    -webkit-column-count: 12;
    column-count: 12;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 12;
    -webkit-columns: 12;
    columns: 12; }
  .no-csscolumns .row-masonry-md-12 {
    text-align: justify; }
  .no-csscolumns .row-masonry-md-12:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-md-12 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 7%; } }

@media only screen and (min-width: 992px) {
  .row-masonry-lg-1 {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 1;
    -webkit-columns: 1;
    columns: 1; }
  .no-csscolumns .row-masonry-lg-1 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-1:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-1 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 99%; }
  .row-masonry-lg-2 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 2;
    -webkit-columns: 2;
    columns: 2; }
  .no-csscolumns .row-masonry-lg-2 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-2:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-2 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 49%; }
  .row-masonry-lg-3 {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 3;
    -webkit-columns: 3;
    columns: 3; }
  .no-csscolumns .row-masonry-lg-3 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-3:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-3 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 32%; }
  .row-masonry-lg-4 {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 4;
    -webkit-columns: 4;
    columns: 4; }
  .no-csscolumns .row-masonry-lg-4 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-4:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-4 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 24%; }
  .row-masonry-lg-5 {
    -moz-column-count: 5;
    -webkit-column-count: 5;
    column-count: 5;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 5;
    -webkit-columns: 5;
    columns: 5; }
  .no-csscolumns .row-masonry-lg-5 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-5:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-5 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 19%; }
  .row-masonry-lg-6 {
    -moz-column-count: 6;
    -webkit-column-count: 6;
    column-count: 6;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 6;
    -webkit-columns: 6;
    columns: 6; }
  .no-csscolumns .row-masonry-lg-6 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-6:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-6 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 15%; }
  .row-masonry-lg-7 {
    -moz-column-count: 7;
    -webkit-column-count: 7;
    column-count: 7;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 7;
    -webkit-columns: 7;
    columns: 7; }
  .no-csscolumns .row-masonry-lg-7 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-7:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-7 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 13%; }
  .row-masonry-lg-8 {
    -moz-column-count: 8;
    -webkit-column-count: 8;
    column-count: 8;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 8;
    -webkit-columns: 8;
    columns: 8; }
  .no-csscolumns .row-masonry-lg-8 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-8:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-8 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 11%; }
  .row-masonry-lg-9 {
    -moz-column-count: 9;
    -webkit-column-count: 9;
    column-count: 9;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 9;
    -webkit-columns: 9;
    columns: 9; }
  .no-csscolumns .row-masonry-lg-9 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-9:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-9 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 10%; }
  .row-masonry-lg-10 {
    -moz-column-count: 10;
    -webkit-column-count: 10;
    column-count: 10;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 10;
    -webkit-columns: 10;
    columns: 10; }
  .no-csscolumns .row-masonry-lg-10 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-10:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-10 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 9%; }
  .row-masonry-lg-11 {
    -moz-column-count: 11;
    -webkit-column-count: 11;
    column-count: 11;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 11;
    -webkit-columns: 11;
    columns: 11; }
  .no-csscolumns .row-masonry-lg-11 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-11:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-11 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 8%; }
  .row-masonry-lg-12 {
    -moz-column-count: 12;
    -webkit-column-count: 12;
    column-count: 12;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 12;
    -webkit-columns: 12;
    columns: 12; }
  .no-csscolumns .row-masonry-lg-12 {
    text-align: justify; }
  .no-csscolumns .row-masonry-lg-12:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-lg-12 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 7%; } }

@media only screen and (min-width: 1200px) {
  .row-masonry-xl-1 {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 1;
    -webkit-columns: 1;
    columns: 1; }
  .no-csscolumns .row-masonry-xl-1 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-1:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-1 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 99%; }
  .row-masonry-xl-2 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 2;
    -webkit-columns: 2;
    columns: 2; }
  .no-csscolumns .row-masonry-xl-2 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-2:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-2 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 49%; }
  .row-masonry-xl-3 {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 3;
    -webkit-columns: 3;
    columns: 3; }
  .no-csscolumns .row-masonry-xl-3 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-3:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-3 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 32%; }
  .row-masonry-xl-4 {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 4;
    -webkit-columns: 4;
    columns: 4; }
  .no-csscolumns .row-masonry-xl-4 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-4:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-4 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 24%; }
  .row-masonry-xl-5 {
    -moz-column-count: 5;
    -webkit-column-count: 5;
    column-count: 5;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 5;
    -webkit-columns: 5;
    columns: 5; }
  .no-csscolumns .row-masonry-xl-5 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-5:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-5 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 19%; }
  .row-masonry-xl-6 {
    -moz-column-count: 6;
    -webkit-column-count: 6;
    column-count: 6;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 6;
    -webkit-columns: 6;
    columns: 6; }
  .no-csscolumns .row-masonry-xl-6 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-6:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-6 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 15%; }
  .row-masonry-xl-7 {
    -moz-column-count: 7;
    -webkit-column-count: 7;
    column-count: 7;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 7;
    -webkit-columns: 7;
    columns: 7; }
  .no-csscolumns .row-masonry-xl-7 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-7:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-7 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 13%; }
  .row-masonry-xl-8 {
    -moz-column-count: 8;
    -webkit-column-count: 8;
    column-count: 8;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 8;
    -webkit-columns: 8;
    columns: 8; }
  .no-csscolumns .row-masonry-xl-8 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-8:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-8 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 11%; }
  .row-masonry-xl-9 {
    -moz-column-count: 9;
    -webkit-column-count: 9;
    column-count: 9;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 9;
    -webkit-columns: 9;
    columns: 9; }
  .no-csscolumns .row-masonry-xl-9 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-9:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-9 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 10%; }
  .row-masonry-xl-10 {
    -moz-column-count: 10;
    -webkit-column-count: 10;
    column-count: 10;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 10;
    -webkit-columns: 10;
    columns: 10; }
  .no-csscolumns .row-masonry-xl-10 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-10:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-10 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 9%; }
  .row-masonry-xl-11 {
    -moz-column-count: 11;
    -webkit-column-count: 11;
    column-count: 11;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 11;
    -webkit-columns: 11;
    columns: 11; }
  .no-csscolumns .row-masonry-xl-11 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-11:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-11 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 8%; }
  .row-masonry-xl-12 {
    -moz-column-count: 12;
    -webkit-column-count: 12;
    column-count: 12;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 12;
    -webkit-columns: 12;
    columns: 12; }
  .no-csscolumns .row-masonry-xl-12 {
    text-align: justify; }
  .no-csscolumns .row-masonry-xl-12:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .no-csscolumns .row-masonry-xl-12 > .col-masonry {
    vertical-align: top;
    display: inline-block;
    width: 7%; } }

/* ========================================================================
     Component: widget
 ========================================================================== */
.widget {
  margin-bottom: 20px;
  border: 0; }
  .widget.panel,
  .widget .panel {
    overflow: hidden; }
  .widget .lateral-picture {
    position: relative;
    display: block;
    height: 240px;
    width: auto;
    overflow: hidden; }
    .widget .lateral-picture > img {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 100%;
      width: auto; }

/* ========================================================================
     Component: animate
 ========================================================================== */
@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

@keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort; }

@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    -ms-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort; }

@-webkit-keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

@keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    -ms-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

.zoomInShort {
  -webkit-animation-name: zoomInShort;
  animation-name: zoomInShort; }

@-webkit-keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

@keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    -ms-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

.zoomBack {
  -webkit-animation-name: zoomBack;
  animation-name: zoomBack; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    -ms-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

/*!
 * table-grid (http://mdo.github.io/table-grid)
 * Released under MIT, (c) 2014 Mark Otto
 */
/*
 * The Grid
 */
@media only screen and (min-width: 768px) {
  /* Add `.table-grid` for the table */
  .table-grid {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed; }
  /* Add `.col` for the table cells, or columns */
  .col {
    display: table-cell;
    vertical-align: top;
    height: 100%; }
    .col.col-full-center {
      vertical-align: middle; }
  /* Set the widths */
  .col-1 {
    width: 8.333333%; }
  .col-2 {
    width: 16.666667%; }
  .col-3 {
    width: 25%; }
  .col-4 {
    width: 33.333333%; }
  .col-5 {
    width: 41.666667%; }
  .col-6 {
    width: 50%; }
  .col-7 {
    width: 58.333333%; }
  .col-8 {
    width: 66.666667%; }
  .col-9 {
    width: 75%; }
  .col-10 {
    width: 83.333333%; }
  .col-11 {
    width: 91.666667%; }
  .col-12 {
    width: 100%; }
  .col-xxs {
    width: 60px; }
  .col-xs {
    width: 90px; }
  .col-sm {
    width: 150px; }
  .col-sd {
    width: 200px; }
  .col-md {
    width: 240px; }
  .col-lg {
    width: 280px; }
  .col-xl {
    width: 320px; }
  .col-xxl {
    width: 360px; }
  .col-wide {
    width: 100%; }
  .col-auto {
    width: auto; }
  /* Padded columns */
  .table-grid-padded {
    margin-left: -1rem;
    margin-right: -1rem; }
  .table-grid-padded .table-grid {
    border-spacing: 1rem 0; } }

@media only screen and (max-width: 991px) {
  .table-grid-desktop.table-grid,
  .table-grid-desktop .col {
    display: block;
    width: auto; } }

/*
 * Vertically center grid content
 *
 * Requires content within the column to be inline or inline-block.
 */
.table-grid-align-middle .col {
  vertical-align: middle; }

/* ========================================================================
     Component: todo
 ========================================================================== */
.todo .todo-item-list {
  position: relative; }

.todo .todo-item .todo-edit {
  display: none;
  cursor: pointer; }

.todo .todo-item:hover .todo-edit {
  display: inline; }

.todo .todo-item.todo-complete .todo-edit {
  display: none; }

.todo textarea {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

.todo .todo-complete {
  background: #edf1f2;
  opacity: .6;
  color: #232735; }
  .todo .todo-complete .todo-title {
    text-decoration: line-through; }

.todo .panel-group > .panel {
  border: 1px;
  margin: 0; }
  .todo .panel-group > .panel .panel-body {
    border-bottom: 1px solid #edf1f2; }

/* ========================================================================
     Component: nestable
 ========================================================================== */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px; }

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none; }

.dd-list .dd-list {
  padding-left: 30px; }

.dd-collapsed .dd-list {
  display: none; }

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px; }

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 10px 10px;
  text-decoration: none;
  border: 1px solid #ebebeb;
  background: #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.dd-handle:hover {
  background: #fff; }

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 30px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold; }

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0; }

.dd-item > button[data-action="collapse"]:before {
  content: '-'; }

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px; }

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999; }

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0; }

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1); }

.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd; }

@media only screen and (min-width: 700px) {
  .dd + .dd {
    margin-left: 2%; } }

.dd-hover > .dd-handle {
  background: #2ea8e5 !important; }

.dd3-content {
  display: block;
  margin: 5px 0;
  padding: 10px 10px 10px 50px;
  text-decoration: none;
  border: 1px solid #ebebeb;
  background: #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0; }

.dd3-item > button {
  margin-left: 40px; }

.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 40px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #ebebeb;
  background: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.dd3-handle:before {
  content: '≡';
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #ccc;
  font-size: 20px;
  font-weight: normal; }

.dd3-handle:hover {
  background: #f7f7f7; }

.sector-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-auto-rows: 52px;
  gap: 3px; }
  @media (min-width: 992px) {
    .sector-grid {
      grid-template-columns: repeat(auto-fill, minmax(98px, 1fr)); } }

.sector-grid__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dde6e9;
  border-radius: 5px;
  background-color: #BECED7;
  cursor: pointer;
  overflow: hidden;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  transition: background-color ease 400ms; }
  .sector-grid__item::before {
    content: "";
    background-color: #000;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: opacity linear 200ms; }
  .sector-grid__item:hover::before {
    opacity: 0.3; }
  .sector-grid__item.is-winning {
    background-color: #23B4DF; }
  .sector-grid__item.is-losing {
    background-color: #DA7878; }
  .sector-grid__item.is-tie {
    background-color: #B363CF; }
  .sector-grid__item.is-closed {
    background-color: #BECED7; }
  .sector-grid__item > span {
    position: relative; }
  .sector-grid__item .spinner {
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px; }
    .sector-grid__item .spinner div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      top: -2px;
      left: -2px;
      border: 2px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent; }
      .sector-grid__item .spinner div:nth-child(1) {
        animation-delay: -0.45s; }
      .sector-grid__item .spinner div:nth-child(2) {
        animation-delay: -0.3s; }
      .sector-grid__item .spinner div:nth-child(3) {
        animation-delay: -0.15s; }

.legend-wrapper {
  display: flex;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .legend-wrapper {
      max-width: 500px; } }
  @media (max-width: 991px) {
    .legend-wrapper {
      flex-wrap: wrap; } }

.legend-wrapper .legend {
  display: flex;
  align-items: center; }
  @media (max-width: 991px) {
    .legend-wrapper .legend {
      max-width: 50%;
      flex-basis: 50%;
      margin-bottom: 12px; } }

.legend-wrapper .legend__color {
  width: 24px;
  height: 18px;
  border-radius: 5px;
  background-color: #BECED7;
  margin-right: 8px;
  color: #656565; }

.legend-wrapper .legend__label {
  font-size: 12px; }

.legend-wrapper .legend--winning .legend__color {
  background-color: #23B4DF; }

.legend-wrapper .legend--losing .legend__color {
  background-color: #DA7878; }

.legend-wrapper .legend--tie .legend__color {
  background-color: #B363CF; }

.result-header-stats {
  padding: 40px 60px;
  text-align: center; }
  @media (max-width: 991px) {
    .result-header-stats.is-hidden {
      display: none; } }

.result-header-chart {
  position: relative;
  padding: 32px;
  border-radius: 24px;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 8px 16px rgba(15, 34, 67, 0.06), 0px 0px 1px rgba(15, 34, 67, 0.08);
  height: 275px;
  opacity: 0;
  transition: opacity linear 250ms; }
  .result-header-chart.is-ready {
    opacity: 1; }
  @media (max-width: 991px) {
    .result-header-chart.is-hidden {
      display: none; } }

.section-stats__numbers {
  font-size: 20px;
  color: #656565; }

.section-stats__number {
  font-weight: bold;
  color: #131E26; }

.section-stats__label {
  font-weight: bold;
  color: #656565;
  font-size: 14px; }

.result-winner__label {
  font-weight: bold;
  color: #131E26; }

.sector-modal__party {
  display: flex;
  align-items: center; }
  .sector-modal__party .form-group {
    width: 100%; }

.sector-modal__logo {
  display: flex;
  justify-content: center;
  width: 70px;
  margin-right: 16px; }
  .sector-modal__logo img {
    max-width: 100%;
    height: 60px; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@media only screen and (max-width: 767px) {
  body.election-manual.modal-open {
    overflow: auto; } }

.query-builder {
  position: relative; }

.query {
  position: relative;
  border: 2px solid transparent;
  --padding-x: 18px; }
  .query.is-open {
    border-color: #5d9cec; }
    .query.is-open .query__button {
      border-color: transparent;
      border-bottom-color: rgba(0, 0, 0, 0.1); }
    .query.is-open .query__content {
      display: block; }
  .query .btn svg, .query .ColVis .ColVis_Button svg, .ColVis .query .ColVis_Button svg {
    width: 1em;
    height: 1em; }

.query__button {
  display: flex;
  align-items: center;
  background-color: #fff;
  font-family: monospace;
  font-size: 16px;
  margin: 0;
  width: 100%;
  text-align: left;
  padding: 8px var(--padding-x);
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .query__button svg {
    width: 1em;
    height: 1em;
    margin-left: auto; }

.query__content {
  display: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none; }

.query__section-header {
  padding: 8px var(--padding-x);
  background-color: #e4eaec; }

.query__section-body {
  padding: 8px var(--padding-x); }

.query__actions {
  display: flex;
  align-items: center;
  gap: 8px; }

.query__subtitle {
  font-weight: bold; }

/* ========================================================================
     Component: chart-flot
 ======================================================================== */
.flot-chart {
  display: block;
  width: 100%;
  height: 250px; }
  .flot-chart .legend > table tr td {
    padding: 3px; }
  .flot-chart .legend > table tr td:first-child {
    padding-left: 3px; }
  .flot-chart .legend > table tr td:last-child {
    padding-right: 3px; }
  .flot-chart .legend > table tr + tr td {
    padding-top: 0; }
  .flot-chart .legend > div:first-child {
    border-color: rgba(0, 0, 0, 0.1) !important; }
  .flot-chart .legend .legendColorBox > div,
  .flot-chart .legend .legendColorBox > div > div {
    border-radius: 400px; }

.flot-chart-content {
  width: 100%;
  height: 100%; }

.flot-pie-label {
  padding: 3px 5px;
  font-size: 10px;
  text-align: center;
  color: #fff; }

#flotTip {
  position: relative;
  padding: 5px;
  font-size: 12px !important;
  border-radius: 2px !important;
  border-color: transparent !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  color: #f1f1f1;
  z-index: 5; }

/* ========================================================================
   Component: chart-easypie
 ========================================================================== */
.easypie-chart {
  display: inline-block;
  position: relative;
  padding: 0 6px; }
  .easypie-chart span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    margin-left: -50%;
    height: 30px;
    margin-top: -15px;
    font-size: 20px; }
  .easypie-chart canvas {
    max-width: 100%; }

/* ========================================================================
     Component: form-elements
 ========================================================================== */
/**
 * Custom form elements
 *    - Checkbox 
 *    - Radios
 *    - Switch
 *    - Rounded inputs
 */
.c-checkbox,
.c-radio {
  margin-right: 4px; }
  .c-checkbox *,
  .c-radio * {
    cursor: pointer; }
  .c-checkbox input,
  .c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important; }
  .c-checkbox span,
  .c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: -20px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: 5px;
    text-align: center; }
    .c-checkbox span:before,
    .c-radio span:before {
      margin-left: 1px; }
  .c-checkbox:hover span,
  .c-radio:hover span {
    border-color: #5d9cec; }
  .form-inline .c-checkbox span, .form-inline
  .c-radio span {
    margin-left: 0; }
  .c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
  .c-radio.c-checkbox-rounded span,
  .c-radio.c-radio-rounded span {
    border-radius: 500px; }

/* override for radio */
.c-radio span {
  border-radius: 500px; }

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle; }

/* Checked state */
.c-checkbox,
.c-radio {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-checkbox input[type=checkbox]:checked + span:before,
  .c-checkbox input[type=radio]:checked + span:before,
  .c-radio input[type=checkbox]:checked + span:before,
  .c-radio input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-checkbox input[type=checkbox]:checked + span,
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=checkbox]:checked + span,
  .c-radio input[type=radio]:checked + span {
    border-color: #5d9cec;
    background-color: #5d9cec; }
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=radio]:checked + span {
    background-color: #fff; }
    .c-checkbox input[type=radio]:checked + span:before,
    .c-radio input[type=radio]:checked + span:before {
      color: #5d9cec; }
  .c-checkbox input[type=checkbox]:disabled + span,
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=checkbox]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-checkbox input[type=radio]:disabled + span:before,
    .c-radio input[type=radio]:disabled + span:before {
      color: #ddd; }

.c-radio.c-radio-nofont {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-radio.c-radio-nofont span:before {
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    border-color: #5d9cec;
    background-color: #5d9cec; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    background-color: #fff; }
    .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
      background-color: #5d9cec; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-radio.c-radio-nofont input[type=radio]:disabled + span:before {
      background-color: #ddd; }

.form-control-rounded {
  border-radius: 100px; }

.switch .form-control, .switch body .bootstrap-tagsinput, body .switch .bootstrap-tagsinput {
  padding-top: 7px;
  margin-bottom: 0; }

.switch * {
  cursor: pointer; }

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1; }

.switch span {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 100px;
  transition: all .5s;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset;
  vertical-align: middle; }

.switch span:after {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #ddd;
  border-radius: 400px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: all .2s; }

.switch.switch-lg span {
  width: 50px;
  height: 25px; }

.switch.switch-lg span:after {
  height: 23px;
  width: 23px; }

.switch.switch-sm span {
  width: 30px;
  height: 15px; }

.switch.switch-sm span:after {
  height: 13px;
  width: 13px; }

.switch input:checked + span {
  background-color: #5d9cec;
  border-color: #5d9cec;
  transition: all .5s; }

.switch input:checked + span:after {
  left: 50%;
  transition: all .2s; }

.form-control-feedback {
  left: 16px;
  right: auto; }
  .form-control + .form-control-feedback, body .bootstrap-tagsinput + .form-control-feedback,
  .form-control + .parsley-errors-list + .form-control-feedback, body .bootstrap-tagsinput + .parsley-errors-list + .form-control-feedback {
    right: 0;
    left: auto; }
  .input-sm + .form-control-feedback,
  .input-sm + .parsley-errors-list + .form-control-feedback {
    top: 0; }

.input-md {
  width: 260px; }

.note-editor {
  background-image: -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
  background-image: linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 20px;
  background-color: #fff;
  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none; }
  .note-editor.note-editor-margin {
    background-image: -webkit-linear-gradient(0deg, transparent 49px, #ffe0c4 49px, #ffe0c4 51px, transparent 51px), -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
    background-image: linear-gradient(90deg, transparent 49px, #ffe0c4 49px, #ffe0c4 51px, transparent 51px), linear-gradient(#eee 0.1em, transparent 0.1em);
    padding-left: 55px; }

body .chosen-container-single .chosen-single abbr {
  top: 11px; }

/* ========================================================================
     Component: form-validation
 ========================================================================== */
.parsley-error {
  border-color: #f05050 !important; }

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0; }
  .parsley-errors-list.filled {
    display: block; }
  .parsley-errors-list > li {
    font-size: 12px;
    list-style: none;
    color: #f05050; }

/* ========================================================================
     Component: form-tags
 ========================================================================== */
body .bootstrap-tagsinput {
  height: auto;
  min-height: 35px;
  margin-bottom: 0; }

body .bootstrap-tagsinput .tag {
  color: #fff; }

/* ========================================================================
     Component: form-wizard
 ========================================================================== */
.wizard, .tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden; }

.wizard a, .tabcontrol a {
  outline: 0; }

.wizard ul, .tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0; }

.wizard ul > li, .tabcontrol ul > li {
  display: block;
  padding: 0; }

.wizard > .steps .current-info, .tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em; }

.wizard > .content > .title, .tabcontrol > .content > .title {
  position: absolute;
  left: -999em; }

.wizard > .steps {
  position: relative;
  display: block;
  width: 100%; }
  .wizard > .steps .number {
    font-size: 1.429em; }
  .wizard > .steps > ul > li {
    width: 25%; }

.wizard > .content {
  background: #fff;
  display: block;
  margin: 0.5em;
  min-height: 300px;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
  .wizard > .content > .body {
    float: left;
    position: absolute;
    width: 100%;
    height: 95%;
    padding: 2.5% 0; }
    .wizard > .content > .body ul {
      list-style: disc !important; }
      .wizard > .content > .body ul > li {
        display: list-item; }
    .wizard > .content > .body > iframe {
      border: 0 none;
      width: 100%;
      height: 100%; }
    .wizard > .content > .body input {
      display: block; }
    .wizard > .content > .body input.error {
      background: #fbe3e4;
      border: 1px solid #fbc2c4;
      color: #8a1f11; }
    .wizard > .content > .body label {
      display: inline-block;
      margin-bottom: 0.5em; }
    .wizard > .content > .body label.error {
      color: #f05050;
      margin-left: 1.5em;
      padding: 0; }
    .wizard > .content > .body .checkbox label.error {
      display: block !important; }

.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%; }
  .wizard > .actions > ul {
    display: inline-block;
    text-align: right; }
    .wizard > .actions > ul > li {
      margin: 0 0.5em; }

.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%; }
  .wizard.vertical > .steps > ul > li {
    float: none;
    width: 100%; }

.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 65%; }

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%; }
  .wizard.vertical > .actions > ul > li {
    margin: 0 0 0 1em; }

.wizard > .steps > ul > li, .wizard > .actions > ul > li {
  float: left; }

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 0;
  font-weight: bold;
  color: #515253; }
  .wizard > .steps a > small, .wizard > .steps a:hover > small, .wizard > .steps a:active > small {
    font-weight: normal;
    color: #909FA7; }

.wizard > .steps .disabled a, .wizard > .steps .disabled a:hover, .wizard > .steps .disabled a:active {
  background: #fcfcfc;
  color: #515253;
  cursor: default; }

.wizard > .steps .current a, .wizard > .steps .current a:hover, .wizard > .steps .current a:active {
  background: #5d9cec;
  color: #fff;
  cursor: default; }
  .wizard > .steps .current a > small, .wizard > .steps .current a:hover > small, .wizard > .steps .current a:active > small {
    color: #fff; }

.wizard > .steps .done a, .wizard > .steps .done a:hover, .wizard > .steps .done a:active {
  background: #fcfcfc;
  color: #515253; }

.wizard > .steps .error a, .wizard > .steps .error a:hover, .wizard > .steps .error a:active {
  background: #ff3111;
  color: #fff; }

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  display: inline-block;
  padding: 6px 16px;
  font-size: 13px;
  border-radius: 3px;
  text-decoration: none;
  color: #fff;
  background-color: #5d9cec;
  border-color: #5d9cec; }
  .wizard > .actions a:focus, .wizard > .actions a.focus,
  .wizard > .actions a:hover:focus,
  .wizard > .actions a:hover.focus,
  .wizard > .actions a:active:focus,
  .wizard > .actions a:active.focus {
    color: #fff;
    background-color: #4b91ea;
    border-color: #3886e8; }
  .wizard > .actions a:hover,
  .wizard > .actions a:hover:hover,
  .wizard > .actions a:active:hover {
    color: #fff;
    background-color: #4b91ea;
    border-color: #3886e8; }
  .wizard > .actions a:active, .wizard > .actions a.active,
  .open > .wizard > .actions a.dropdown-toggle,
  .wizard > .actions a:hover:active,
  .wizard > .actions a:hover.active,
  .open >
  .wizard > .actions a:hover.dropdown-toggle,
  .wizard > .actions a:active:active,
  .wizard > .actions a:active.active,
  .open >
  .wizard > .actions a:active.dropdown-toggle {
    color: #fff;
    background-color: #4b91ea;
    border-color: #3886e8; }
    .wizard > .actions a:active:hover, .wizard > .actions a:active:focus, .wizard > .actions a:active.focus, .wizard > .actions a.active:hover, .wizard > .actions a.active:focus, .wizard > .actions a.active.focus,
    .open > .wizard > .actions a.dropdown-toggle:hover,
    .open > .wizard > .actions a.dropdown-toggle:focus,
    .open > .wizard > .actions a.dropdown-toggle.focus,
    .wizard > .actions a:hover:active:hover,
    .wizard > .actions a:hover:active:focus,
    .wizard > .actions a:hover:active.focus,
    .wizard > .actions a:hover.active:hover,
    .wizard > .actions a:hover.active:focus,
    .wizard > .actions a:hover.active.focus,
    .open >
    .wizard > .actions a:hover.dropdown-toggle:hover,
    .open >
    .wizard > .actions a:hover.dropdown-toggle:focus,
    .open >
    .wizard > .actions a:hover.dropdown-toggle.focus,
    .wizard > .actions a:active:active:hover,
    .wizard > .actions a:active:active:focus,
    .wizard > .actions a:active:active.focus,
    .wizard > .actions a:active.active:hover,
    .wizard > .actions a:active.active:focus,
    .wizard > .actions a:active.active.focus,
    .open >
    .wizard > .actions a:active.dropdown-toggle:hover,
    .open >
    .wizard > .actions a:active.dropdown-toggle:focus,
    .open >
    .wizard > .actions a:active.dropdown-toggle.focus {
      color: #fff;
      background-color: #4b91ea;
      border-color: #3886e8; }
  .wizard > .actions a:active, .wizard > .actions a.active,
  .open > .wizard > .actions a.dropdown-toggle,
  .wizard > .actions a:hover:active,
  .wizard > .actions a:hover.active,
  .open >
  .wizard > .actions a:hover.dropdown-toggle,
  .wizard > .actions a:active:active,
  .wizard > .actions a:active.active,
  .open >
  .wizard > .actions a:active.dropdown-toggle {
    background-image: none; }
  .wizard > .actions a.disabled:hover, .wizard > .actions a.disabled:focus, .wizard > .actions a.disabled.focus, .wizard > .actions a[disabled]:hover, .wizard > .actions a[disabled]:focus, .wizard > .actions a[disabled].focus,
  fieldset[disabled] .wizard > .actions a:hover,
  fieldset[disabled] .wizard > .actions a:focus,
  fieldset[disabled] .wizard > .actions a.focus,
  .wizard > .actions a:hover.disabled:hover,
  .wizard > .actions a:hover.disabled:focus,
  .wizard > .actions a:hover.disabled.focus,
  .wizard > .actions a:hover[disabled]:hover,
  .wizard > .actions a:hover[disabled]:focus,
  .wizard > .actions a:hover[disabled].focus,
  fieldset[disabled]
  .wizard > .actions a:hover:hover,
  fieldset[disabled]
  .wizard > .actions a:hover:focus,
  fieldset[disabled]
  .wizard > .actions a:hover.focus,
  .wizard > .actions a:active.disabled:hover,
  .wizard > .actions a:active.disabled:focus,
  .wizard > .actions a:active.disabled.focus,
  .wizard > .actions a:active[disabled]:hover,
  .wizard > .actions a:active[disabled]:focus,
  .wizard > .actions a:active[disabled].focus,
  fieldset[disabled]
  .wizard > .actions a:active:hover,
  fieldset[disabled]
  .wizard > .actions a:active:focus,
  fieldset[disabled]
  .wizard > .actions a:active.focus {
    background-color: #5d9cec;
    border-color: #5d9cec; }
  .wizard > .actions a .badge,
  .wizard > .actions a:hover .badge,
  .wizard > .actions a:active .badge {
    color: #5d9cec;
    background-color: #fff; }

.wizard > .actions .disabled a, .wizard > .actions .disabled a:hover, .wizard > .actions .disabled a:active {
  color: #333;
  background: #fff;
  border: 1px solid #eaeaea; }

.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%; }
  .tabcontrol > .steps > ul {
    position: relative;
    margin: 6px 0 0 0;
    top: 1px;
    z-index: 1; }
    .tabcontrol > .steps > ul > li {
      float: left;
      margin: 5px 2px 0 0;
      padding: 1px;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
      .tabcontrol > .steps > ul > li > a {
        color: #5f5f5f;
        display: inline-block;
        border: 0 none;
        margin: 0;
        padding: 10px 30px;
        text-decoration: none; }
      .tabcontrol > .steps > ul > li > a:hover {
        text-decoration: none; }
    .tabcontrol > .steps > ul > li:hover {
      background: #edecec;
      border: 1px solid #bbb;
      padding: 0; }
    .tabcontrol > .steps > ul > li.current {
      background: #fff;
      border: 1px solid #bbb;
      border-bottom: 0 none;
      padding: 0 0 1px 0;
      margin-top: 0; }
      .tabcontrol > .steps > ul > li.current > a {
        padding: 15px 30px 10px 30px; }

.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px; }
  .tabcontrol > .content > .body {
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
    padding: 2.5%; }
    .tabcontrol > .content > .body ul {
      list-style: disc !important; }
      .tabcontrol > .content > .body ul > li {
        display: list-item; }

/* ========================================================================
     Component: form-imgcrop
 ========================================================================== */
.img-container,
.img-preview {
  background-color: #f7f7f7;
  overflow: hidden;
  width: 100%;
  text-align: center; }

.img-container {
  min-height: 200px;
  max-height: 340px;
  margin-bottom: 20px; }

.docs-preview {
  margin-right: -15px;
  margin-bottom: 10px; }

.img-preview {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px; }

.img-preview > img {
  max-width: 100%; }

.preview-lg {
  width: 263px;
  height: 148px; }

.preview-md {
  width: 139px;
  height: 78px; }

.preview-sm {
  width: 69px;
  height: 39px; }

.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0; }

.cropper-container img {
  display: block;
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  height: 100%;
  min-height: 0 !important;
  max-height: none !important;
  image-orientation: 0deg !important; }

/* ========================================================================
     Component: datatable
 ========================================================================== */
.dataTables_filter input {
  margin-left: 10px; }

.dataTables_length select {
  margin-right: 10px; }

table.dataTable tfoot > tr > th {
  color: #a1a2a3;
  background: #f1f2f3; }

.panel .dataTables_wrapper .row {
  margin: 10px 0; }

.panel .dataTable {
  width: 100% !important; }

.ColVis .ColVis_Button {
  background-color: #fff;
  background-image: none !important;
  box-shadow: 0 0 0 #000 !important;
  cursor: pointer;
  height: 31px; }
  .ColVis .ColVis_Button > span {
    color: #656565; }

.ColVis_collectionBackground {
  display: none; }

ul.ColVis_collection {
  background-color: #fff !important; }
  ul.ColVis_collection > li {
    border: 0 !important;
    background-image: none !important;
    box-shadow: 0 0 0 #000 !important;
    margin: 0; }
    ul.ColVis_collection > li > label {
      margin: 0; }
      ul.ColVis_collection > li > label > span {
        color: #888;
        vertical-align: top;
        font-weight: normal; }
        ul.ColVis_collection > li > label > span:hover {
          color: #555; }
      ul.ColVis_collection > li > label input[type=checkbox]:checked + span,
      ul.ColVis_collection > li > label input[type=radio]:checked + span {
        background-color: transparent; }

.ColVis_catcher {
  cursor: pointer; }

.table-responsive .dataTables_wrapper .row {
  margin: 0; }

@media only screen and (max-width: 768px) {
  div.dataTables_filter label,
  div.dataTables_length label,
  div.dataTables_paginate {
    float: none; }
    div.dataTables_filter label select,
    div.dataTables_length label select,
    div.dataTables_paginate select {
      display: inline-block; }
  .dataTables_wrapper > .row > div {
    float: none;
    text-align: center;
    width: 100%; } }

table.dataTable.table-tasks td,
table.dataTable.table-tasks th {
  width: auto !important; }
  table.dataTable.table-tasks td:last-child,
  table.dataTable.table-tasks th:last-child {
    width: 1% !important;
    white-space: nowrap; }

table.dataTable.table-tasks td.row-indicator,
table.dataTable.table-tasks th.row-indicator {
  width: 10px !important;
  box-sizing: border-box; }

.row-indicator--is-new {
  background-color: #20afe0; }

@media only screen and (min-width: 1600px) {
  .xl\:text-nowrap {
    white-space: nowrap; } }

a.btn.icon-only > i, .ColVis a.icon-only.ColVis_Button > i {
  margin-right: 0; }

/* ========================================================================
     Component: table-extras
 ========================================================================== */
.table > thead > tr > th {
  padding: 14px 8px;
  color: #888; }

.table > tbody > tr > td {
  vertical-align: middle; }
  .table > tbody > tr > td > .media img {
    width: 36px;
    height: 36px;
    margin: 0 auto; }

.table .checkbox {
  margin: 0 auto;
  width: 20px; }

.table .progress {
  margin-bottom: 0; }

.table .radial-bar {
  margin-bottom: 0;
  margin: 0 auto; }

.jqstooltip {
  box-sizing: content-box; }

#table-ext-1 th:nth-child(1) {
  width: 3%; }

#table-ext-1 th:nth-child(2) {
  width: 5%; }

#table-ext-1 th:nth-child(7) {
  width: 5%; }

#table-ext-1 th:nth-child(9) {
  width: 5%; }

#table-ext-2 th:nth-child(1) {
  width: 5%; }

#table-ext-2 th:nth-child(3) {
  width: 10%; }

/* ========================================================================
    Component: jqgrid
 ========================================================================== */
.ui-widget {
  font-family: inherit; }

.ui-jqgrid {
  border-radius: 4px;
  border-width: 3px 1px 1px 1px;
  border-color: #cfdbe2; }
  .ui-jqgrid * {
    max-width: 100%; }
  .ui-jqgrid .ui-jqgrid-titlebar {
    position: relative;
    padding: 8px 15px;
    border-bottom: 0;
    line-height: 1.528571429;
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    background-color: #fff;
    color: #444; }
    .ui-jqgrid .ui-jqgrid-titlebar .ui-jqgrid-titlebar-close {
      margin-right: 10px; }
  .ui-jqgrid .ui-jqgrid-pager {
    position: relative;
    padding: 8px 14px !important;
    height: auto !important;
    max-width: 100%; }
    .ui-jqgrid .ui-jqgrid-pager .ui-pg-table .navtable .ui-corner-all {
      border-radius: 0px; }
    .ui-jqgrid .ui-jqgrid-pager .ui-pg-input,
    .ui-jqgrid .ui-jqgrid-pager .ui-pg-selbox {
      height: 24px;
      padding: 4px;
      font-size: 12px;
      line-height: 1.5;
      color: #3a3f51;
      background-color: #fff;
      background-image: none;
      border: 1px solid #dde6e9;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
    .ui-jqgrid .ui-jqgrid-pager .ui-pager-control {
      position: relative; }
    .ui-jqgrid .ui-jqgrid-pager .ui-pg-button:hover {
      padding: 1px;
      border: 0px; }
  .ui-jqgrid .loading {
    position: absolute;
    top: 45%;
    left: 45%;
    width: auto;
    height: auto;
    z-index: 101;
    padding: 6px;
    margin: 5px;
    text-align: center;
    font-weight: bold;
    display: none;
    border-width: 2px !important;
    font-size: 11px; }

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 0;
  background: #fafbfc;
  font-weight: bold;
  color: #888; }
  .ui-state-default.ui-state-hover,
  .ui-widget-content .ui-state-default.ui-state-hover,
  .ui-widget-header .ui-state-default.ui-state-hover {
    background: #fafbfc; }
  .ui-state-default .ui-state-default,
  .ui-widget-content .ui-state-default .ui-state-default,
  .ui-widget-header .ui-state-default .ui-state-default {
    border-top: 0 !important; }

.ui-widget-header {
  background-image: none; }

.ui-jqgrid tr.ui-row-ltr td {
  border-right: 0;
  text-align: left;
  border-color: #edf1f2;
  background-color: inherit; }

.ui-jqgrid tr.ui-search-toolbar th {
  border-top-width: 0px !important;
  border-top-color: inherit !important;
  border-top-style: ridge !important; }

.ui-jqgrid tr.jqgrow td {
  padding: 8px; }

.ui-jqgrid .ui-jqgrid-btable {
  border-collapse: separate; }

.ui-jqgrid .ui-jqgrid-htable {
  border-collapse: separate; }
  .ui-jqgrid .ui-jqgrid-htable th {
    padding: 14px 8px; }

.ui-jqgrid .ui-jqgrid-bdiv,
.ui-jqgrid .ui-jqgrid-btable {
  width: auto !important; }

.ui-jqgrid tr.jqfoot td {
  min-height: 25px;
  padding: 5px 2px; }

.ui-jqgrid tr.footrow td {
  height: 30px; }

.ui-jqgrid tr.footrow-ltr td {
  border: 0; }

.ui-search-toolbar input[type="text"] {
  font-size: 12px;
  height: 15px;
  border: 1px solid #e4eaec;
  border-radius: 0px; }

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: #edf1f2;
  border-collapse: separate;
  color: #656565; }

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: #edf1f2;
  color: #656565; }

.ui-widget-content {
  color: #656565;
  font-size: 13px;
  background-image: none; }

tr.ui-widget-content {
  border: 0; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #edf1f2;
  background: #fff;
  font-weight: normal;
  color: #888; }

.ui-jqdialog .ui-jqdialog-titlebar {
  padding: 10px 10px; }

.ui-jqdialog .ui-jqdialog-title {
  float: none !important; }

.ui-jqdialog > .ui-resizable-se {
  position: absolute; }

.table-expense-wrapper {
  width: 100%;
  overflow: auto; }

.table-expense input[type="number"] {
  -moz-appearance: textfield;
  text-align: right; }
  .table-expense input[type="number"]::-webkit-outer-spin-button, .table-expense input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.table-expense__tr {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1.5fr)) repeat(3, minmax(150px, 1fr)); }
  .table-expense__tr:last-child .table-expense__td {
    border-bottom-width: 2px; }
  .table-expense__tr:hover .table-expense__td {
    background-color: #f9f9f9; }

.table-expense__th,
.table-expense__td {
  padding: 6px;
  border-color: #eee;
  border-style: solid;
  border-width: 0;
  border-top-width: 2px;
  border-right-width: 2px;
  color: #000; }
  .table-expense__th:first-child,
  .table-expense__td:first-child {
    border-left-width: 2px; }

.table-expense__th {
  padding: 18px 6px;
  font-weight: bold;
  background-color: #F4F7FA;
  color: #000; }

.table-expense__td {
  transition: background-color 250ms ease-out; }

.table-expense--dark .table-expense__th {
  background-color: #131E26;
  color: #fff; }

.table-expense-highlight {
  font-size: 15px;
  font-weight: bold; }

.table-expense-spinner {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 8px; }
  .table-expense-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    top: -2px;
    left: -2px;
    border: 2px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent; }
    .table-expense-spinner div:nth-child(1) {
      animation-delay: -0.45s; }
    .table-expense-spinner div:nth-child(2) {
      animation-delay: -0.3s; }
    .table-expense-spinner div:nth-child(3) {
      animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* ========================================================================
     Component: plugins
// - User this stylesheet to include single css styles and fixes
//   for plugins
 ========================================================================== */
/**
 * Bootstrap Slider
 */
body {
  /**
   * Chosen
   */
  /**
   * Filestyle
   */ }
  body .slider-handle,
  body .slider-track,
  body .slider-selection {
    background-image: none;
    box-shadow: 0 0 0 #000; }
  body .slider-handle {
    background-color: #fff;
    border: 1px solid #5d9cec;
    opacity: 1; }
  body .slider-track {
    background-color: #fff;
    border: 1px solid #5d9cec; }
  body .slider-selection {
    background-color: #5d9cec; }
  body .slider .tooltip.top .tooltip-arrow {
    border-top-color: #2c3037; }
  body .slider .tooltip.right .tooltip-arrow {
    border-right-color: #2c3037; }
  body .slider .tooltip-inner {
    background-color: #2c3037; }
  body .slider-lg {
    width: 380px; }
  body .chosen-container {
    width: 100% !important;
    max-width: 100%; }
  body .has-error .chosen-container .chosen-choices {
    border-color: #f05050; }
  body .chosen-container-multi .chosen-choices {
    display: block;
    width: 100%;
    height: 35px;
    padding: 6px 16px;
    font-size: 14px;
    line-height: 1.528571429;
    color: #3a3f51;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dde6e9;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
    body .chosen-container-multi .chosen-choices:focus {
      border-color: #66afe9;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
    body .chosen-container-multi .chosen-choices::-moz-placeholder {
      color: #b7bac9;
      opacity: 1; }
    body .chosen-container-multi .chosen-choices:-ms-input-placeholder {
      color: #b7bac9; }
    body .chosen-container-multi .chosen-choices::-webkit-input-placeholder {
      color: #b7bac9; }
    body .chosen-container-multi .chosen-choices[disabled], body .chosen-container-multi .chosen-choices[readonly],
    fieldset[disabled] body .chosen-container-multi .chosen-choices {
      cursor: not-allowed;
      background-color: #edf1f2;
      opacity: 1; }
  body .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    height: auto;
    padding: 0; }
  body .chosen-container-multi .chosen-choices li.search-choice {
    margin: 0 5px 3px 0;
    background-color: #f5f6f7;
    border: 1px solid #e7e9ec;
    background-image: none; }
  body .chosen-container .chosen-results li.highlighted {
    background-color: #a1a2a3;
    background-image: none; }
  body .chosen-container .chosen-drop {
    border-color: #ddd; }
  body .chosen-container .chosen-results li.group-result {
    color: #747576;
    letter-spacing: 0.02em; }
  body .chosen-container-single .chosen-single,
  body .chosen-container-active.chosen-with-drop .chosen-single {
    display: block;
    width: 100%;
    height: 35px;
    padding: 6px 16px;
    font-size: 14px;
    line-height: 1.528571429;
    color: #3a3f51;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dde6e9;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
    body .chosen-container-single .chosen-single:focus,
    body .chosen-container-active.chosen-with-drop .chosen-single:focus {
      border-color: #66afe9;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
    body .chosen-container-single .chosen-single::-moz-placeholder,
    body .chosen-container-active.chosen-with-drop .chosen-single::-moz-placeholder {
      color: #b7bac9;
      opacity: 1; }
    body .chosen-container-single .chosen-single:-ms-input-placeholder,
    body .chosen-container-active.chosen-with-drop .chosen-single:-ms-input-placeholder {
      color: #b7bac9; }
    body .chosen-container-single .chosen-single::-webkit-input-placeholder,
    body .chosen-container-active.chosen-with-drop .chosen-single::-webkit-input-placeholder {
      color: #b7bac9; }
    body .chosen-container-single .chosen-single[disabled], body .chosen-container-single .chosen-single[readonly],
    fieldset[disabled] body .chosen-container-single .chosen-single,
    body .chosen-container-active.chosen-with-drop .chosen-single[disabled],
    body .chosen-container-active.chosen-with-drop .chosen-single[readonly],
    fieldset[disabled]
    body .chosen-container-active.chosen-with-drop .chosen-single {
      cursor: not-allowed;
      background-color: #edf1f2;
      opacity: 1; }
  body .chosen-container-active .chosen-single,
  body .chosen-container-active .chosen-choices {
    border: 1px solid #66afe9; }
  body .chosen-container-single .chosen-drop {
    margin-top: -3px; }
  body .chosen-container-single .chosen-single div {
    top: 6px;
    right: 5px; }
  body .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    color: #b7bac9; }
  body .bootstrap-filestyle {
    max-width: 380px; }
  body [data-classyloader] {
    max-width: 100%; }
  body .select2-container {
    max-width: 100%; }
  body .select2-container--bootstrap .select2-selection--single,
  body .select2-container--bootstrap .select2-selection--multiple {
    border: 1px solid #dde6e9;
    box-shadow: 0 0 0 #000 !important; }

/* ========================================================================
     Component: slim-scroll
 ========================================================================== */
[data-scrollable] {
  display: block; }

.slimScrollBar {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.35) !important;
  border: 0 !important;
  border-radius: 1px !important; }

.slimScrollRail {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
  border-radius: 0 !important;
  bottom: 0; }

/* ========================================================================
   Component: alerts.less
 ========================================================================== */
.alerts {
  position: fixed;
  top: 60px;
  left: 60px;
  z-index: 9090;
  width: 350px; }
  .alerts:hover .alert .close {
    opacity: 1;
    visibility: visible; }

.alerts-top-right, .alerts,
.alerts-bottom-right {
  left: auto;
  right: 60px; }

.alerts-top-center,
.alerts-bottom-center {
  left: 50%;
  margin-left: -175px; }

.alerts-bottom-left,
.alerts-bottom-right,
.alerts-bottom-center {
  top: auto;
  bottom: 60px; }

@media (max-width: 480px) {
  .alerts {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

/* ========================================================================
     Component: notify
 ========================================================================== */
.uk-notify {
  position: fixed;
  top: 50px;
  left: 50px;
  z-index: 1040;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 350px; }

.uk-notify-top-right,
.uk-notify-bottom-right {
  left: auto;
  right: 50px; }

.uk-notify-top-center,
.uk-notify-bottom-center {
  left: 50%;
  margin-left: -175px; }

.uk-notify-bottom-left,
.uk-notify-bottom-right,
.uk-notify-bottom-center {
  top: auto;
  bottom: 50px; }

@media (max-width: 480px) {
  .uk-notify {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

.uk-notify-message {
  position: relative;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 3px;
  padding-right: 35px;
  cursor: pointer; }
  .uk-notify-message.alert.alert-normal {
    background: #444;
    color: #fff; }

.uk-notify-message > .close {
  visibility: hidden; }

.uk-notify-message:hover > .close {
  visibility: visible; }

/* ========================================================================
     Component: calendar
 ========================================================================== */
.calendar-app .fc-button {
  color: #333;
  background-color: #fff;
  border-color: #eaeaea;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  height: auto;
  background-image: none; }
  .calendar-app .fc-button:focus, .calendar-app .fc-button.focus {
    color: #333;
    background-color: whitesmoke;
    border-color: #d6d6d6; }
  .calendar-app .fc-button:hover {
    color: #333;
    background-color: whitesmoke;
    border-color: #d6d6d6; }
  .calendar-app .fc-button:active, .calendar-app .fc-button.active,
  .open > .calendar-app .fc-button.dropdown-toggle {
    color: #333;
    background-color: whitesmoke;
    border-color: #d6d6d6; }
    .calendar-app .fc-button:active:hover, .calendar-app .fc-button:active:focus, .calendar-app .fc-button:active.focus, .calendar-app .fc-button.active:hover, .calendar-app .fc-button.active:focus, .calendar-app .fc-button.active.focus,
    .open > .calendar-app .fc-button.dropdown-toggle:hover,
    .open > .calendar-app .fc-button.dropdown-toggle:focus,
    .open > .calendar-app .fc-button.dropdown-toggle.focus {
      color: #333;
      background-color: whitesmoke;
      border-color: #d6d6d6; }
  .calendar-app .fc-button:active, .calendar-app .fc-button.active,
  .open > .calendar-app .fc-button.dropdown-toggle {
    background-image: none; }
  .calendar-app .fc-button.disabled:hover, .calendar-app .fc-button.disabled:focus, .calendar-app .fc-button.disabled.focus, .calendar-app .fc-button[disabled]:hover, .calendar-app .fc-button[disabled]:focus, .calendar-app .fc-button[disabled].focus,
  fieldset[disabled] .calendar-app .fc-button:hover,
  fieldset[disabled] .calendar-app .fc-button:focus,
  fieldset[disabled] .calendar-app .fc-button.focus {
    background-color: #fff;
    border-color: #eaeaea; }
  .calendar-app .fc-button .badge {
    color: #fff;
    background-color: #333; }
  .calendar-app .fc-button:active, .calendar-app .fc-button.active, .calendar-app .fc-button.fc-state-active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.calendar-app .fc-button .fc-icon {
  margin: 0 .1em;
  font-size: inherit;
  font-family: FontAwesome !important;
  vertical-align: baseline;
  color: #555; }
  .calendar-app .fc-button .fc-icon:after {
    display: none; }

.calendar-app .fc-state-highlight,
.calendar-app .fc-day:hover {
  background-color: #f7f8f9; }

.calendar-app .fc-day-number {
  font-size: 18px;
  font-weight: bold;
  color: #a1a2a3; }

.calendar-app .fc-view {
  overflow: visible; }

.calendar-app .external-events {
  margin: 0; }
  .calendar-app .external-events > div {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 6px 10px;
    color: #fff;
    font-size: 11px;
    border-radius: 2px;
    cursor: move; }
  .calendar-app .external-events:empty {
    content: "EMRTPUY"; }

.calendar-app .external-events-trash {
  position: relative;
  min-height: 120px; }
  .calendar-app .external-events-trash:after {
    content: "\f00d";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #131e26;
    opacity: 0;
    font-family: FontAwesome;
    color: #fff;
    text-align: center;
    font-size: 50px;
    padding: 8%;
    max-height: 100%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: -1; }
  .calendar-app .external-events-trash.active:after {
    opacity: 1;
    z-index: 1; }
  .calendar-app .external-events-trash.hovered:after {
    background-color: #fb797e; }

.calendar-app .external-event-color-selector .circle.selected {
  border: 3px solid rgba(255, 255, 255, 0.5); }

/* ========================================================================
   Component: spinner
 ========================================================================== */
body .whirl {
  position: relative; }
  body .whirl:before {
    content: attr(data-spinner-text);
    display: block;
    background-color: #f0f0f0;
    opacity: 0.7; }
  body .whirl.solid {
    opacity: 1; }
  body .whirl:after {
    border-top-color: #5d9cec; }

body .whirl.blade:after {
  border-bottom-color: #5d9cec; }

body .whirl.double-up:after {
  border-right-color: #5d9cec;
  border-top-color: #5d9cec;
  border-left-color: #5d9cec;
  border-bottom-color: #5d9cec; }

body .whirl.duo:after {
  border-right-color: #5d9cec;
  border-left-color: #5d9cec;
  border-top-color: #7266ba;
  border-bottom-color: #7266ba; }

body .whirl.helicopter:after {
  background-color: #5d9cec; }

body .whirl.ringed:after {
  border-right-color: #23b7e5;
  border-top-color: #7266ba;
  border-left-color: #7266ba;
  border-bottom-color: #7266ba; }

body .whirl.traditional:after {
  border-right-color: #23b7e5;
  border-top-color: #23b7e5;
  border-left-color: #7266ba;
  border-bottom-color: #fad732; }

body .whirl.line:after {
  box-shadow: inset -10px 0px 0px fade(#5d9cec, 50%); }

.no-cssanimations body .whirl:before {
  opacity: .9; }

.no-cssanimations body .whirl:after {
  content: "Loading...";
  width: 40px;
  height: 40px;
  margin: -20px 0 -20px;
  line-height: 40px;
  color: #000;
  opacity: 1;
  border: 0;
  box-shadow: 0 0 0 #000;
  background-color: transparent;
  text-align: center; }

body .ball-pulse > div,
body .ball-grid-pulse > div,
body .square-spin > div,
body .ball-pulse-rise > div,
body .ball-rotate > div,
body .ball-rotate > div:before, body .ball-rotate > div:after,
body .cube-transition > div,
body .ball-zig-zag > div,
body .ball-zig-zag-deflect > div,
body .ball-triangle-path > div,
body .ball-scale > div,
body .line-scale > div,
body .line-scale-party > div,
body .ball-scale-multiple > div,
body .ball-pulse-sync > div,
body .ball-beat > div,
body .line-scale-pulse-out > div,
body .line-scale-pulse-out-rapid > div,
body .ball-spin-fade-loader > div,
body .line-spin-fade-loader > div,
body .triangle-skew-spin > div,
body .ball-grid-beat > div,
body .semi-circle-spin > div {
  background-color: #5d9cec; }

body .ball-clip-rotate-multiple {
  width: 1px; }
  body .ball-clip-rotate-multiple > div {
    border-color: #5d9cec transparent #5d9cec transparent; }
    body .ball-clip-rotate-multiple > div:last-child {
      border-color: transparent #5d9cec transparent #5d9cec; }

body .ball-clip-rotate > div {
  border-color: #5d9cec;
  border-bottom-color: transparent; }

body .ball-clip-rotate-pulse > div:first-child {
  background-color: #5d9cec;
  top: 5px;
  left: -8px; }

body .ball-clip-rotate-pulse > div:last-child {
  border-color: #5d9cec transparent #5d9cec transparent; }

body .square-spin > div,
body .ball-scale-ripple > div,
body .ball-scale-ripple-multiple > div {
  border-color: #5d9cec; }

body .pacman > div:first-of-type {
  border-top-color: #5d9cec;
  border-left-color: #5d9cec;
  border-bottom-color: #5d9cec; }

body .pacman > div:nth-child(2) {
  border-top-color: #5d9cec;
  border-left-color: #5d9cec;
  border-bottom-color: #5d9cec; }

body .pacman > div:nth-child(3), body .pacman > div:nth-child(4), body .pacman > div:nth-child(5), body .pacman > div:nth-child(6) {
  background-color: #5d9cec; }

body .sk-rotating-plane.sk-spinner,
body .sk-double-bounce .sk-double-bounce1, body .sk-double-bounce .sk-double-bounce2,
body .sk-wave .sk-rect,
body .sk-wandering-cubes .sk-cube1, body .sk-wandering-cubes .sk-cube2,
body .sk-chasing-dots .sk-dot1, body .sk-chasing-dots .sk-dot2,
body .sk-three-bounce div,
body .sk-circle .sk-child:before,
body .sk-cube-grid .sk-cube,
body .sk-fading-circle .sk-circle:before,
body .sk-spinner-pulse.sk-spinner,
body .sk-rotating-plane {
  background-color: #5d9cec !important; }

body .sk-wordpress .sk-inner-circle {
  background-color: #fff; }

/* just for loaders.css demo */
.loader-demo {
  height: 150px;
  padding-top: 50px;
  text-align: center; }
  .loader-demo > div {
    display: inline-block; }
  .loader-demo.loader-demo-sk {
    padding-top: 0; }

/* ========================================================================
     Component: gmap
 ========================================================================== */
.gmap {
  height: 300px; }
  .gmap.gmap-sm {
    height: 150px; }

/* ========================================================================
     Component: vector-map
 ========================================================================== */
body .jvectormap-label {
  position: absolute;
  display: none;
  border: solid 1px #313232;
  border-radius: 2px;
  background: #313232;
  color: white;
  padding: 3px 6px;
  opacity: 0.9;
  z-index: 1100; }

body .jvectormap-zoomin,
body .jvectormap-zoomout {
  position: absolute;
  left: 10px;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  background: #515253;
  padding: 5px;
  color: white;
  cursor: pointer;
  line-height: 10px;
  text-align: center; }

body .jvectormap-zoomin {
  top: 10px; }

body .jvectormap-zoomout {
  top: 30px; }

/* ========================================================================
     Component: portlets
 ========================================================================== */
[data-toggle="portlet"] {
  min-height: 30px; }
  [data-toggle="portlet"].portlet-handler,
  [data-toggle="portlet"] .portlet-handler {
    cursor: move; }

.portlets-wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  overflow: auto; }

.portlet.box-placeholder {
  padding: 0; }

#map-leaflet {
  height: 85vh;
  width: 100%; }

.leaflet-control-locationlist {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
  border-radius: 5px; }

.leaflet-touch .leaflet-bar.leaflet-control-locationlist-form {
  border: 0; }

.leaflet-control-locationlist-list {
  border: 0;
  font-size: 15px;
  font-weight: bold; }

.info {
  padding: 6px 8px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  min-width: 100px;
  background-color: white; }

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7; }

.sv-marker {
  display: block;
  font-weight: bold;
  letter-spacing: 2px;
  white-space: nowrap;
  pointer-events: none !important; }

.leaflet-control-infoBox {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  padding: 6px 10px 6px 6px;
  color: #333;
  background-color: white;
  border-radius: 5px; }
  .leaflet-control-infoBox .is-winner {
    background-color: gold; }
  .leaflet-control-infoBox h4 {
    margin-top: 6px;
    color: #777;
    font-size: rem(15px); }
  .leaflet-control-infoBox p {
    margin: 0; }
  .leaflet-control-infoBox table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%; }
  .leaflet-control-infoBox th, .leaflet-control-infoBox td {
    text-align: center;
    padding: 6px; }
  .leaflet-control-infoBox th:first-child,
  .leaflet-control-infoBox td:first-child {
    text-align: left; }
  .leaflet-control-infoBox th:first-child {
    font-size: 16px; }
  .leaflet-control-infoBox tr:nth-child(even) {
    background-color: #f2f2f2; }
  .leaflet-control-infoBox button {
    margin-top: 8px; }

.leaflet-control-layers-group label:not(.leaflet-control-layers-group-label) {
  margin-left: 8px; }

.leaflet-control-layers-group .leaflet-control-layers-group-label {
  font-size: 14px;
  margin-bottom: 5px; }

.fullscreen-icon {
  background-image: url("/img/leaflet-fullscreen.svg"); }

.leaflet-control-infoBox,
.leaflet-control-locationlist,
.leaflet-control-layers.leaflet-control-layers-expanded {
  width: 240px; }

/* ========================================================================
     Component: mailbox
 ========================================================================== */
.mb-boxes {
  overflow: hidden; }
  .mb-boxes .nav > li > a {
    font-weight: 600;
    color: #3a3f51;
    margin: 5px 0;
    -webkit-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease; }
    .mb-boxes .nav > li > a:hover, .mb-boxes .nav > li > a:focus {
      background-color: #C0D0D3; }
  .mb-boxes .nav > li.active > a {
    background-color: #5d9cec !important;
    color: #fff; }
  @media only screen and (min-width: 992px) {
    .mb-boxes {
      height: auto !important;
      visibility: visible !important;
      display: block; } }

@media only screen and (min-width: 992px) {
  .mb-compose-button {
    display: block;
    width: 100%; }
  .mb-toggle-button {
    display: none; } }

.mb-mails > tbody > tr > td {
  border-top-color: transparent;
  cursor: pointer; }

.mb-mails .mb-mail-active {
  background-color: #f7f8f9 !important; }

.mb-mails .mb-mail-avatar {
  float: left;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 3px; }

.mb-mails .mb-mail-date {
  display: block;
  float: right;
  color: #909FA7;
  font-size: 12px;
  font-weight: bold; }

.mb-mails .mb-mail-from {
  font-size: 14px;
  font-weight: 600;
  line-height: 1; }

.mb-mails .mb-mail-meta {
  overflow: hidden; }

.mb-mails .mb-mail-subject {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 6px; }

.mb-mails .mb-mail-preview {
  clear: both;
  margin-top: 10px;
  color: #909FA7;
  margin-top: 2px; }

.mb-mails .mb-attachment {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #e4eaec; }

.mb-view > tbody > tr > td {
  cursor: auto; }

.mb-view .mb-mail-to-list {
  margin-top: 6px; }
  .mb-view .mb-mail-to-list > a {
    margin-right: 5px;
    color: #909FA7; }

/* ========================================================================
     Component: utils
 ========================================================================== */
.ghost {
  opacity: 0.5; }

.strikethrough {
  text-decoration: line-through; }

.m0 {
  margin: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.m {
  margin: 10px !important; }

.ml, .mh {
  margin-left: 10px !important; }

.mr, .mh {
  margin-right: 10px !important; }

.mt, .mv {
  margin-top: 10px !important; }

.mb, .mv {
  margin-bottom: 10px !important; }

.m-sm {
  margin: 5px !important; }

.ml-sm, .mh-sm {
  margin-left: 5px !important; }

.mr-sm, .mh-sm {
  margin-right: 5px !important; }

.mt-sm, .mv-sm {
  margin-top: 5px !important; }

.mb-sm, .mv-sm {
  margin-bottom: 5px !important; }

.m-lg {
  margin: 15px !important; }

.ml-lg, .mh-lg {
  margin-left: 15px !important; }

.mr-lg, .mh-lg {
  margin-right: 15px !important; }

.mt-lg, .mv-lg {
  margin-top: 15px !important; }

.mb-lg, .mv-lg {
  margin-bottom: 15px !important; }

.m-xl {
  margin: 30px !important; }

.ml-xl {
  margin-left: 30px !important; }

.mr-xl {
  margin-right: 30px !important; }

.mt-xl {
  margin-top: 30px !important; }

.mb-xl {
  margin-bottom: 30px !important; }

.p0 {
  padding: 0 !important; }

.pl0, .ph0 {
  padding-left: 0 !important; }

.pr0, .ph0 {
  padding-right: 0 !important; }

.pt0, .pv0 {
  padding-top: 0 !important; }

.pb0, .pv0 {
  padding-bottom: 0 !important; }

.p {
  padding: 10px !important; }

.pl, .ph {
  padding-left: 10px !important; }

.pr, .ph {
  padding-right: 10px !important; }

.pt, .pv {
  padding-top: 10px !important; }

.pb, .pv {
  padding-bottom: 10px !important; }

.p-sm {
  padding: 5px !important; }

.pl-sm, .ph-sm {
  padding-left: 5px !important; }

.pr-sm, .ph-sm {
  padding-right: 5px !important; }

.pt-sm, .pv-sm {
  padding-top: 5px !important; }

.pb-sm, .pv-sm {
  padding-bottom: 5px !important; }

.p-lg {
  padding: 15px !important; }

.pl-lg, .ph-lg {
  padding-left: 15px !important; }

.pr-lg, .ph-lg {
  padding-right: 15px !important; }

.pt-lg, .pv-lg {
  padding-top: 15px !important; }

.pb-lg, .pv-lg {
  padding-bottom: 15px !important; }

.p-xl {
  padding: 30px !important; }

.pl-xl, .ph-xl {
  padding-left: 30px !important; }

.pr-xl, .ph-xl {
  padding-right: 30px !important; }

.pt-xl, .pv-xl {
  padding-top: 30px !important; }

.pb-xl, .pv-xl {
  padding-bottom: 30px !important; }

.b0 {
  border-width: 0 !important; }

.bl0 {
  border-left-width: 0 !important; }

.br0 {
  border-right-width: 0 !important; }

.bt0 {
  border-top-width: 0 !important; }

.bb0 {
  border-bottom-width: 0 !important; }

.br, .b, .ba {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.bl, .b, .ba {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.bt, .b, .ba {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.bb, .b, .ba {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.radius-clear {
  border-radius: 0 !important; }

.shadow-clear {
  box-shadow: 0 0 0 #000 !important; }

.text-white {
  color: #fff; }

.text-inverse {
  color: #131e26; }

.text-alpha {
  color: rgba(255, 255, 255, 0.5); }

.text-pink {
  color: #f532e5; }

.text-purple {
  color: #7266ba; }

.text-dark {
  color: #3a3f51; }

.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5); }

.text-green {
  color: #37bc9b; }

.text-yellow {
  color: #fad732; }

.text-gray-darker {
  color: #232735; }

.text-gray-dark {
  color: #3a3f51; }

.text-gray {
  color: #dde6e9; }

.text-gray-light {
  color: #e4eaec; }

.text-gray-lighter {
  color: #edf1f2; }

.text-hover-blue:hover {
  color: #5d9cec !important; }

.text-sm {
  font-size: 11.9px; }

.text-md {
  font-size: 26.6px; }

.text-lg {
  font-size: 42px; }

.text-nowrap {
  white-space: nowrap; }

.text-thin {
  font-weight: 100 !important; }

.text-normal {
  font-weight: normal !important; }

.text-bold {
  font-weight: bold !important; }

.inline {
  display: inline-block !important; }

.block-center {
  margin: 0 auto; }

.bg-primary {
  background-color: #5d9cec;
  color: #fff !important; }

.bg-primary-light {
  background-color: #8bb8f1;
  color: #fff !important; }

.bg-primary-dark {
  background-color: #2f80e7;
  color: #fff !important; }

.bg-primary small {
  color: inherit; }

.bg-success {
  background-color: #00ad4d;
  color: #fff !important; }

.bg-success-light {
  background-color: #00e064;
  color: #fff !important; }

.bg-success-dark {
  background-color: #007a36;
  color: #fff !important; }

.bg-success small {
  color: inherit; }

.bg-info {
  background-color: #23b7e5;
  color: #fff !important; }

.bg-info-light {
  background-color: #51c6ea;
  color: #fff !important; }

.bg-info-dark {
  background-color: #1797be;
  color: #fff !important; }

.bg-info small {
  color: inherit; }

.bg-warning {
  background-color: #ff902b;
  color: #fff !important; }

.bg-warning-light {
  background-color: #ffab5e;
  color: #fff !important; }

.bg-warning-dark {
  background-color: #f77600;
  color: #fff !important; }

.bg-warning small {
  color: inherit; }

.bg-danger {
  background-color: #f05050;
  color: #fff !important; }

.bg-danger-light {
  background-color: #f47f7f;
  color: #fff !important; }

.bg-danger-dark {
  background-color: #ec2121;
  color: #fff !important; }

.bg-danger small {
  color: inherit; }

.bg-green {
  background-color: #37bc9b;
  color: #fff !important; }

.bg-green-light {
  background-color: #58ceb1;
  color: #fff !important; }

.bg-green-dark {
  background-color: #2b957a;
  color: #fff !important; }

.bg-green small {
  color: inherit; }

.bg-pink {
  background-color: #f532e5;
  color: #fff !important; }

.bg-pink-light {
  background-color: #f763eb;
  color: #fff !important; }

.bg-pink-dark {
  background-color: #e90bd6;
  color: #fff !important; }

.bg-pink small {
  color: inherit; }

.bg-purple {
  background-color: #7266ba;
  color: #fff !important; }

.bg-purple-light {
  background-color: #9289ca;
  color: #fff !important; }

.bg-purple-dark {
  background-color: #564aa3;
  color: #fff !important; }

.bg-purple small {
  color: inherit; }

.bg-inverse {
  background-color: #131e26;
  color: #fff !important; }

.bg-inverse-light {
  background-color: #243948;
  color: #fff !important; }

.bg-inverse-dark {
  background-color: #020304;
  color: #fff !important; }

.bg-inverse small {
  color: inherit; }

.bg-yellow {
  background-color: #fad732;
  color: #fff !important; }

.bg-yellow-light {
  background-color: #fbe164;
  color: #fff !important; }

.bg-yellow-dark {
  background-color: #f3ca06;
  color: #fff !important; }

.bg-yellow small {
  color: inherit; }

.bg-white {
  background-color: #fff;
  color: inherit !important; }

.bg-gray-darker {
  background-color: #232735;
  color: #fff !important; }

.bg-gray-dark {
  background-color: #3a3f51;
  color: #fff !important; }

.bg-gray {
  background-color: #dde6e9;
  color: #515253 !important; }

.bg-gray-light {
  background-color: #e4eaec;
  color: #515253 !important; }

.bg-gray-lighter {
  background-color: #edf1f2;
  color: #515253 !important; }

.bg-cover {
  background-size: cover; }

.bg-transparent {
  background-color: transparent; }

.thumb8 {
  width: 8px !important;
  height: 8px !important; }

.thumb16 {
  width: 16px !important;
  height: 16px !important; }

.thumb24 {
  width: 24px !important;
  height: 24px !important; }

.thumb32 {
  width: 32px !important;
  height: 32px !important; }

.thumb48 {
  width: 48px !important;
  height: 48px !important; }

.thumb64 {
  width: 64px !important;
  height: 64px !important; }

.thumb80 {
  width: 80px !important;
  height: 80px !important; }

.thumb96 {
  width: 96px !important;
  height: 96px !important; }

.thumb128 {
  width: 128px !important;
  height: 128px !important; }

.align-middle {
  vertical-align: middle; }

.align-top {
  vertical-align: top; }

.align-bottom {
  vertical-align: bottom; }

.bg-center {
  background-position: center center;
  background-size: cover; }

.list-icon em {
  font-size: 14px;
  width: 40px;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  line-height: 30px; }

.list-icon div:hover em {
  -webkit-transform: scale(3, 3);
  -ms-transform: scale(3, 3);
  -o-transform: scale(3, 3);
  transform: scale(3, 3); }

.wd-xxs {
  width: 60px; }

.wd-xs {
  width: 90px; }

.wd-sm {
  width: 150px; }

.wd-sd {
  width: 200px; }

.wd-md {
  width: 240px; }

.wd-lg {
  width: 280px; }

.wd-xl {
  width: 320px; }

.wd-xxl {
  width: 360px; }

.wd-wide {
  width: 100%; }

.wd-auto {
  width: auto; }

.wd-zero {
  width: 0; }

.label-inverse {
  background-color: #131e26; }
  .label-inverse[href]:hover, .label-inverse[href]:focus {
    background-color: #020304; }

.label-green {
  background-color: #37bc9b; }
  .label-green[href]:hover, .label-green[href]:focus {
    background-color: #2b957a; }

.label-pink {
  background-color: #f532e5; }
  .label-pink[href]:hover, .label-pink[href]:focus {
    background-color: #e90bd6; }

.label-purple {
  background-color: #7266ba; }
  .label-purple[href]:hover, .label-purple[href]:focus {
    background-color: #564aa3; }

.alert-purple {
  background-color: #7266ba;
  border-color: #7266ba;
  color: #fff; }
  .alert-purple hr {
    border-top-color: #6254b2; }
  .alert-purple .alert-link {
    color: #e6e6e6; }

.alert-green {
  background-color: #37bc9b;
  border-color: #37bc9b;
  color: #fff; }
  .alert-green hr {
    border-top-color: #31a88b; }
  .alert-green .alert-link {
    color: #e6e6e6; }

.alert-pink {
  background-color: #f532e5;
  border-color: #f532e5;
  color: #fff; }
  .alert-pink hr {
    border-top-color: #f41ae2; }
  .alert-pink .alert-link {
    color: #e6e6e6; }

.alert-inverse {
  background-color: #131e26;
  border-color: #131e26;
  color: #fff; }
  .alert-inverse hr {
    border-top-color: #0b1115; }
  .alert-inverse .alert-link {
    color: #e6e6e6; }

.clickable {
  cursor: pointer; }

.abs-center-container {
  position: relative; }

.abs-center {
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .abs-center.abs-fixed {
    position: fixed;
    z-index: 999; }
  .abs-center.abs-right {
    left: auto;
    right: 20px;
    text-align: right; }
  .abs-center.abs-left {
    right: auto;
    left: 20px;
    text-align: left; }

@media (max-height: 720px) {
  .abs-center {
    position: relative; } }

.link-unstyled {
  text-decoration: none !important;
  outline: none !important; }

.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

/** 
 * Displays hidden content on hover. Add this class to the parent element.
 * Markup
 *   <div class="hover-visible">
 *    <div class="hover-hidden"></div>
 *   </div>
 */
.hover-visible .hover-hidden {
  display: none; }

.hover-visible:hover .hover-hidden {
  display: block; }

.hover-visible:hover .hover-inline {
  display: inline-block; }

.media-box,
.media-box-body {
  overflow: hidden;
  zoom: 1; }

.media-box,
.media-box .media-box {
  margin-top: 15px; }

.media-box:first-child {
  margin-top: 0; }

.media-box-object {
  display: block; }

.media-box-heading {
  margin: 0 0 5px; }

.media-box > .pull-left {
  margin-right: 10px; }

.media-box > .pull-right {
  margin-left: 10px; }

.media-box-list {
  padding-left: 0;
  list-style: none; }

.keep-ratio-16-9 {
  position: relative;
  overflow: hidden; }
  .keep-ratio-16-9::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: calc(9 * 100 / 16 * 1%); }
  .keep-ratio-16-9 > * {
    position: absolute; }
  .keep-ratio-16-9 > img,
  .keep-ratio-16-9 > figure,
  .keep-ratio-16-9 > picture,
  .keep-ratio-16-9 > iframe,
  .keep-ratio-16-9 > video,
  .keep-ratio-16-9 > .noimg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }

.icon[disabled] {
  color: #e4eaec; }

.u-animate-spin {
  animation: spin 1s linear infinite; }

@keyframes spin {
  to {
    transform: rotate(1turn); } }

/* ========================================================================
     Component: print
 ========================================================================== */
@media print {
  .sidebar,
  .topnavbar,
  .offsidebar,
  .btn,
  .ColVis .ColVis_Button {
    display: none !important;
    width: 0 !important;
    height: 0 !important; }
  .wrapper,
  .wrapper > section,
  .content-wrapper {
    margin: 0 !important;
    /* remove margin used for sidebar and expand the content */
    padding: 0 !important;
    width: 100% !important; }
  .content-wrapper {
    overflow: hidden !important; } }

.setting-color {
  padding: 0 5px; }
  .setting-color > label {
    display: block;
    position: relative;
    margin: 0 10px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer; }
    .setting-color > label:first-child {
      margin-left: 0; }
    .setting-color > label:last-child {
      margin-right: 0; }
    .setting-color > label > .color {
      display: block;
      height: 18px; }
    .setting-color > label > .split {
      display: block; }
      .setting-color > label > .split:before, .setting-color > label > .split:after {
        content: " ";
        display: table; }
      .setting-color > label > .split:after {
        clear: both; }
      .setting-color > label > .split > .color {
        display: block;
        height: 37.5px; }
        .setting-color > label > .split > .color:first-child {
          float: left;
          width: 70%; }
        .setting-color > label > .split > .color:last-child {
          float: right;
          width: 30%; }
    .setting-color > label > .icon-check {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -20px;
      margin-left: -10px;
      text-align: center;
      font-size: 1.33333333em;
      vertical-align: -15%;
      color: #fff;
      opacity: 0; }
    .setting-color > label > input[type="radio"] {
      position: absolute;
      opacity: 0;
      visibility: hidden; }
      .setting-color > label > input[type="radio"]:checked + .icon-check {
        opacity: 1 !important; }

.flatdoc-wrapper {
  display: block;
  margin: -15px; }
  @media only screen and (min-width: 768px) {
    .flatdoc-wrapper {
      margin: -20px; } }
  .flatdoc-wrapper .flatdoc {
    width: 100%;
    height: 100%; }
    @media only screen and (min-width: 768px) {
      .flatdoc-wrapper .flatdoc {
        display: table;
        table-layout: fixed;
        border-spacing: 0; } }
  .flatdoc-wrapper .flatdoc-menu {
    border-right: 1px solid #e1e2e3;
    font-size: 14px; }
    @media only screen and (min-width: 768px) {
      .flatdoc-wrapper .flatdoc-menu {
        position: relative;
        display: table-cell;
        height: 100%;
        vertical-align: top;
        width: 180px; }
        .flatdoc-wrapper .flatdoc-menu > ul {
          padding-top: 20px; } }
    .flatdoc-wrapper .flatdoc-menu ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .flatdoc-wrapper .flatdoc-menu ul.level-1 > li > a {
        padding-top: 25px;
        padding-bottom: 15px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e1e2e3;
        pointer-events: none; }
      .flatdoc-wrapper .flatdoc-menu ul.level-2 > li + li > a,
      .flatdoc-wrapper .flatdoc-menu ul.level-2 > li:first-child > a {
        background-color: rgba(255, 255, 255, 0.75); }
      .flatdoc-wrapper .flatdoc-menu ul a {
        position: relative;
        display: block;
        padding: 10px;
        padding-right: 30px; }
        .flatdoc-wrapper .flatdoc-menu ul a, .flatdoc-wrapper .flatdoc-menu ul a:visited {
          color: #3a3f51; }
        .flatdoc-wrapper .flatdoc-menu ul a:hover {
          color: #2e3241;
          background-color: rgba(255, 255, 255, 0.75);
          text-decoration: none; }
      .flatdoc-wrapper .flatdoc-menu ul a.level-1 {
        font-family: montserrat, sans-serif;
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: bold; }
        .flatdoc-wrapper .flatdoc-menu ul a.level-1, .flatdoc-wrapper .flatdoc-menu ul a.level-1:visited {
          color: #3a3f51; }
        .flatdoc-wrapper .flatdoc-menu ul a.level-1:hover {
          color: #3a3f51; }
      .flatdoc-wrapper .flatdoc-menu ul a.level-2 {
        font-weight: 600; }
      .flatdoc-wrapper .flatdoc-menu ul a.level-3 {
        font-weight: normal;
        font-size: 0.9em;
        padding-left: 15px; }
      .flatdoc-wrapper .flatdoc-menu ul a.active {
        font-weight: bold !important; }
        .flatdoc-wrapper .flatdoc-menu ul a.active, .flatdoc-wrapper .flatdoc-menu ul a.active:visited, .flatdoc-wrapper .flatdoc-menu ul a.active:hover {
          color: #505050 !important; }
        .flatdoc-wrapper .flatdoc-menu ul a.active:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          margin-top: -2px;
          right: 15px;
          width: 9px;
          height: 3px;
          border-radius: 2px;
          background: #3a3f51; }
  .flatdoc-wrapper .flatdoc-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 1px;
    padding-bottom: 50px;
    background-color: #fff; }
    @media only screen and (min-width: 768px) {
      .flatdoc-wrapper .flatdoc-content {
        display: table-cell;
        height: 100%;
        vertical-align: top; } }
    .flatdoc-wrapper .flatdoc-content pre {
      background-color: #f3f6fb;
      border: 1px solid #dde6e9; }
      .flatdoc-wrapper .flatdoc-content pre code {
        display: inline-block;
        white-space: pre; }
        .flatdoc-wrapper .flatdoc-content pre code .string, .flatdoc-wrapper .flatdoc-content pre code .number {
          color: #2b957a; }
        .flatdoc-wrapper .flatdoc-content pre code .init {
          color: #2b957a; }
        .flatdoc-wrapper .flatdoc-content pre code .keyword {
          color: #3a3f51;
          font-weight: bold; }
        .flatdoc-wrapper .flatdoc-content pre code .comment {
          color: #909FA7; }
    .flatdoc-wrapper .flatdoc-content > h1 {
      padding: 11px 0;
      margin: 0;
      line-height: 1; }
    .flatdoc-wrapper .flatdoc-content > h2, .flatdoc-wrapper .flatdoc-content > h3 {
      padding: 20px;
      border-top: 1px solid #f1f2f3;
      margin: 0 -20px; }
  .flatdoc-wrapper .button {
    display: inline-block;
    padding: 6px 16px;
    font-size: 13px;
    border-radius: 3px;
    text-decoration: none;
    color: #fff;
    background-color: #23b7e5;
    border-color: #23b7e5; }
    .flatdoc-wrapper .button:focus, .flatdoc-wrapper .button.focus {
      color: #fff;
      background-color: #1aacda;
      border-color: #189ec8; }
    .flatdoc-wrapper .button:hover {
      color: #fff;
      background-color: #1aacda;
      border-color: #189ec8; }
    .flatdoc-wrapper .button:active, .flatdoc-wrapper .button.active,
    .open > .flatdoc-wrapper .button.dropdown-toggle {
      color: #fff;
      background-color: #1aacda;
      border-color: #189ec8; }
      .flatdoc-wrapper .button:active:hover, .flatdoc-wrapper .button:active:focus, .flatdoc-wrapper .button:active.focus, .flatdoc-wrapper .button.active:hover, .flatdoc-wrapper .button.active:focus, .flatdoc-wrapper .button.active.focus,
      .open > .flatdoc-wrapper .button.dropdown-toggle:hover,
      .open > .flatdoc-wrapper .button.dropdown-toggle:focus,
      .open > .flatdoc-wrapper .button.dropdown-toggle.focus {
        color: #fff;
        background-color: #1aacda;
        border-color: #189ec8; }
    .flatdoc-wrapper .button:active, .flatdoc-wrapper .button.active,
    .open > .flatdoc-wrapper .button.dropdown-toggle {
      background-image: none; }
    .flatdoc-wrapper .button.disabled:hover, .flatdoc-wrapper .button.disabled:focus, .flatdoc-wrapper .button.disabled.focus, .flatdoc-wrapper .button[disabled]:hover, .flatdoc-wrapper .button[disabled]:focus, .flatdoc-wrapper .button[disabled].focus,
    fieldset[disabled] .flatdoc-wrapper .button:hover,
    fieldset[disabled] .flatdoc-wrapper .button:focus,
    fieldset[disabled] .flatdoc-wrapper .button.focus {
      background-color: #23b7e5;
      border-color: #23b7e5; }
    .flatdoc-wrapper .button .badge {
      color: #23b7e5;
      background-color: #fff; }

.cart .read-price {
  background: transparent;
  border: 0; }

.cart .read-quantity {
  background: transparent;
  border: 0; }

.cart .table-hover > tbody > tr:hover {
  background-color: #ace4f5; }

.cart .form-quantity {
  min-width: 40px; }

.cart .form-price {
  min-width: 54px; }

.cart-review {
  padding-left: 6%;
  padding-right: 6%;
  margin-top: 40px; }
  .cart-review .radio.read-radio label {
    cursor: default; }

@media only screen and (min-width: 768px) {
  .catalog {
    position: relative;
    min-height: 200px;
    max-width: 1600px; }
    .catalog .row-catalog {
      display: flex;
      flex-wrap: wrap; }
      .catalog .row-catalog .cell {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #dde6e9; }
      .catalog .row-catalog .thumbnail {
        height: 100%;
        position: relative;
        border: 0; }
        .catalog .row-catalog .thumbnail .caption {
          padding-bottom: 30px; }
        .catalog .row-catalog .thumbnail .control-bar {
          position: absolute;
          bottom: 0px; }
        .catalog .row-catalog .thumbnail .caption .h4 {
          color: #5d9cec; } }

.catalog .control-bar label.form-control, .catalog .control-bar body label.bootstrap-tagsinput, body .catalog .control-bar label.bootstrap-tagsinput {
  margin: 0;
  padding: 6px 12px;
  max-width: 150px;
  display: inline-block;
  font-weight: normal; }
  .catalog .control-bar label.form-control .help-label, .catalog .control-bar body label.bootstrap-tagsinput .help-label, body .catalog .control-bar label.bootstrap-tagsinput .help-label {
    opacity: 0.5; }
  .catalog .control-bar label.form-control .form-quantity, .catalog .control-bar body label.bootstrap-tagsinput .form-quantity, body .catalog .control-bar label.bootstrap-tagsinput .form-quantity {
    width: 38px;
    border: 0; }
  .catalog .control-bar label.form-control .form-price, .catalog .control-bar body label.bootstrap-tagsinput .form-price, body .catalog .control-bar label.bootstrap-tagsinput .form-price {
    width: 55px;
    border: 0; }

.catalog .cell .thumbnail:hover {
  border-color: #dde6e9; }

#modal-edit-expense-claim-item .modal-body {
  max-height: 500px; }
