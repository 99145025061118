.query-builder {
  position: relative;
}

.query {
  position: relative;
  border: 2px solid transparent;
  --padding-x: 18px;

  &.is-open {
    border-color: $brand-primary;

    .query__button {
      border-color: transparent;
      border-bottom-color: rgba(0,0,0,.1);
    }

    .query__content {
      display: block;
    }
  }

  .btn {
    svg {
      width: 1em;
      height: 1em;
    }
  }
}

.query__button {
  display: flex;
  align-items: center;
  background-color: #fff;
  font-family: monospace;
  font-size: 16px;
  margin: 0;
  width: 100%;
  text-align: left;
  padding: 8px var(--padding-x);
  font-weight: bold;
  border: 1px solid rgba(0,0,0,.1);

  svg {
    width: 1em;
    height: 1em;
    margin-left: auto;
  }
}

.query__content {
  display: none;
  // padding: 16px var(--padding-x);
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.1);
  border-top: none;
}

.query__section-header {
  padding: 8px var(--padding-x);
  background-color: $gray-light;
}

.query__section-body {
  padding: 8px var(--padding-x);
}

.query__actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.query__subtitle {
  font-weight: bold;
}
