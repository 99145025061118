$table-expense-border-width: 2px;

.table-expense-wrapper {
    width: 100%;
    overflow: auto;
}

.table-expense {
    input[type="number"] {
        -moz-appearance: textfield;
        text-align: right;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.table-expense__tr {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1.5fr)) repeat(3, minmax(150px, 1fr));

    &:last-child {
        .table-expense__td {
            border-bottom-width: $table-expense-border-width;
        }
    }

    &:hover {
        .table-expense__td {
            background-color: #f9f9f9;
        }
    }
}

.table-expense__th,
.table-expense__td {
    padding: 6px;
    border-color: #eee;
    border-style: solid;
    border-width: 0;
    border-top-width: $table-expense-border-width;
    border-right-width: $table-expense-border-width;
    color: #000;

    &:first-child {
        border-left-width: $table-expense-border-width;
    }
}

.table-expense__th {
    padding: 18px 6px;
    font-weight: bold;
    background-color: #F4F7FA;
    color: #000;
}

.table-expense__td {
    transition: background-color 250ms ease-out;
}

.table-expense--dark {
    .table-expense__th {
        background-color: #131E26;
        color: #fff;
    }
}

.table-expense-highlight {
    font-size: 15px;
    font-weight: bold;
}

.table-expense-spinner {
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 14px;
        height: 14px;
        top: -2px;
        left: -2px;
        border: 2px solid #000;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #000 transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}