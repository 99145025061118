.cart {
    .read-price {
        background: transparent;
        border: 0;
    }

    .read-quantity {
        background: transparent;
        border: 0;
    }

    .table-hover {
        > tbody > tr:hover {
            background-color: $brand-info-lighter;
        }
    }

    .form-quantity {
        min-width:40px;
    }

    .form-price {
        min-width:54px;
    }
}

.cart-review {
    padding-left: 6%;
    padding-right: 6%;
    margin-top: 40px;

    .radio.read-radio {
        label {
            cursor: default;
        }
    }
}

@media only screen and (min-width: $mq-tablet) {
    .catalog {
        position: relative;
        min-height: 200px;
        max-width: 1600px;

        .row-catalog {
            display: flex;
            flex-wrap: wrap;

            .cell {
                margin-bottom: 25px;
                padding-bottom: 25px;
                border-bottom: 1px solid $gray;
            }

            .thumbnail {
                height: 100%;
                position: relative;
                border: 0;

                .caption {
                    padding-bottom:30px;
                }

                .control-bar {
                    position: absolute;
                    bottom: 0px;
                }

                .caption .h4 {
                    color: #5d9cec;
                }
            }
        }
    }
}

.catalog {
    .control-bar label.form-control {
        margin: 0;
        padding: 6px 12px;
        max-width: 150px;
        display: inline-block;
        font-weight:normal;

        .help-label {
            opacity: 0.5;
        }

        .form-quantity {
            width:38px;
            border:0;
        }

        .form-price {
            width:55px;
            border:0;
        }
    }

    .cell .thumbnail:hover {
        border-color: $gray;
    }
}
