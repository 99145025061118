/* @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap"; */
/*!
 *
 * Angle - Bootstrap Admin App + jQuery
 *
 * Version: 3.3.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */


/* [Table of contents]

1. Bootstrap reset
  1.1 bootstrap
  1.2 bootstrap-reset

2. Typography
  2.1 typo

3. Layout
  3.1 layout / .wrapper .content-wrapper
  3.2 top-navbar / .top-navbar
  3.3 sidebar / .sidebar
  3.4 offsidebar / .offsidebar

4. Components
  4.1  button-extra
  4.2  placeholder / .box-placeholder
  4.3  panels
  4.4  progress-extra
  4.5  radial-bar / .radial-bar
  4.6  user-block / .user-block
  4.7  circles / .circle
  4.8  timeline / .timeline
  4.9  dropdown-extra
  4.10 row-extra / .row-table .row-flush
  4.11 half-float / .half-float
  4.12 masonry-grid / .row-masonry
  4.13 widget / .widget
  4.14 typeahead / .typeahead-ctrl
  4.15 animate
  4.16 table-grid
  4.17 todo / .todo
  4.18 nestable

5. Charts
  5.1 chart-flot / .flot-chart
  5.2 chart-easypie / .easypie-chart

6. Form elements
  6.1 form-elements
  6.2 form-validation
  6.3 form-tags
  6.4 form-wizard
  6.5 form-imgcrop

7. Tables
  7.1 datatable
  7.2 table-extras
  7.3 jqgrid

8. Plugins
  8.1 plugins
  8.2 slim-scroll / .slimScrollBar
  8.3 alerts / .alerts
  8.4 notifiy / .notifiy
  8.5 calendar / .fc-*
  8.6 spinner / .whirl
  8.7 gmap / .gmap
  8.8 vector-map / vector-map
  8.9 portlets / .portlet

9. Utilities
  9.1 utils

10. Print CSS
  10.1 print

11. Settings
  11.1 settings

12. Documentation
  12.1 docs
 */

// Override bootstrap variables
@import "app/variables";

// Bootstrap
@import "bootstrap/bootstrap/_mixins";

// Global definition of media queries
@import "app/media-queries";
// Utilities
@import "app/utils-definitions";

// Bootstrap reset
@import "app/bootstrap-reset";

// Typography
@import "app/typo";

// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";

// Components
@import "app/breadcrumbs";
@import "app/button-extra";
@import "app/placeholder";
@import "app/panels";
@import "app/progress-extra";
@import "app/radial-bar";
@import "app/user-block";
@import "app/circles";
@import "app/timeline";
@import "app/dropdown-extra";
@import "app/row-extra";
@import "app/half-float";
@import "app/masonry-grid";
@import "app/widget";
@import "app/animate";
@import "app/table-grid";
@import "app/todo";
@import "app/nestable";
@import "app/sector-grid";
@import "app/query-builder";


// Charts
@import "app/chart-flot";
@import "app/chart-easypie";

// Form elements
@import "app/form-elements";
@import "app/form-validation";
@import "app/form-tags";
@import "app/form-wizard";
@import "app/form-imgcrop";

// Tables
@import "app/datatable";
@import "app/table-extras";
@import "app/jqgrid";
@import "app/table-expense";

// Plugins
@import "app/plugins";
@import "app/slim-scroll";
@import "app/alerts";
@import "app/notify";
@import "app/calendar";
@import "app/spinner";
@import "app/gmap";
@import "app/vector-map";
@import "app/portlets";
@import "app/leaflet";

// Mailbox
@import "app/mailbox";

// Utilities
@import "app/utils";

// Print CSS
@import "app/print";

// Settings
@import "app/settings";

// Documentation
@import "app/docs";

// Cart
@import "app/cart";

// Custom Modal
@import "app/modals";
